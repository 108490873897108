import { AxiosResponse } from 'axios';
import { ApiService } from 'common/services';
import { ExportStatusResponse } from './export-data-api.types';

class ExportDataApiService extends ApiService {
  getExportStatus = (id: string): Promise<AxiosResponse<ExportStatusResponse>> => {
    return this.get(`/exports/${id}/status`);
  };
}

export const exportDataApi = new ExportDataApiService(process.env.REACT_APP_AI_API);
