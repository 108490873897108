import { Box, Typography } from '@mui/material';
import { AdvonLogoIcon } from 'common/svg/advon-logo-icon';
import { Styles } from 'common/types/styles';

const styles: Styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    position: 'relative',
  },
  logoContainer: {
    position: 'absolute',
    top: 60,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
};

export function UnavailableView() {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.logoContainer}>
        <AdvonLogoIcon />
      </Box>
      <Typography variant="h3" color="GrayText">
        No retailers are currently available. Please contact support for assistance.
      </Typography>
    </Box>
  );
}
