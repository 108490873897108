import { Box, IconButton, InputAdornment, Popover, TextField, Typography } from '@mui/material';
import { Styles } from 'common/types/styles';
import { FieldLabelWrapper } from 'common/ui/inputs/field-label-wrapper';
import { ChangeEvent, memo, MouseEvent, ReactElement, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TuneIcon from '@mui/icons-material/Tune';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import { SelectField, SelectFieldOption } from 'common/ui/inputs/select-field';

export type ConditionType = '>' | '<' | '=';

const ConditionTypeOptions: SelectFieldOption[] = ['>', '<', '='].map(o => ({ label: o, value: o }));

const styles: Styles = {
  filter: { display: 'flex', alignItems: 'center', gap: 2 },
  filterInput: { flexGrow: 1 },
  fieldLaberWrapper: { width: '100%' },
  container: { display: 'flex', flexDirection: 'column', gap: 2, p: 2, boxSizing: 'border-box' },
};

interface Props {
  onFilterChange: (type: ConditionType, value: number) => void;
}

export const KWsSuggestionsFilter = memo(({ onFilterChange }: Props): ReactElement => {
  const [anchor, setAnchor] = useState<HTMLButtonElement>(null);
  const [conditionType, setConditionType] = useState<ConditionType>(null);
  const [conditionValue, setConditionValue] = useState<number>(null);

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchor(e.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleConditionValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConditionValue(Number(e.target.value));
  };

  return (
    <>
      <LightTooltip title="Filters">
        <IconButton onClick={handleOpen} size="small">
          <TuneIcon fontSize="small" />
        </IconButton>
      </LightTooltip>
      <Popover onClose={handleClose} anchorEl={anchor} open={Boolean(anchor)}>
        <Box sx={styles.container}>
          <FieldLabelWrapper label="Condition" sx={styles.fieldLaberWrapper}>
            <Box sx={styles.filter}>
              <Typography>SFR</Typography>
              <SelectField
                value={conditionType}
                onChange={setConditionType}
                sx={styles.filterInput}
                options={ConditionTypeOptions}
                variant="standard"
              />
              <TextField
                sx={styles.filterInput}
                type="number"
                value={conditionValue}
                onChange={handleConditionValueChange}
                variant="standard"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LightTooltip title="Submit Search">
                        <IconButton
                          onClick={() => {
                            onFilterChange(conditionType, conditionValue);
                          }}
                          size="small"
                        >
                          <SearchIcon fontSize="small" />
                        </IconButton>
                      </LightTooltip>
                      <LightTooltip title="Clear Suggestions">
                        <IconButton
                          onClick={() => {
                            onFilterChange(null, null);
                            setConditionType(null);
                            setConditionValue(null);
                          }}
                          size="small"
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </LightTooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </FieldLabelWrapper>
        </Box>
      </Popover>
    </>
  );
});
