import { AIInstructionEntry } from 'common/types/common';
import {
  Dispatch,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

export const instructionCtx = createContext({
  instructionModalOpen: false as boolean,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setInstructionModalOpen: ((value: boolean) => undefined) as Dispatch<SetStateAction<boolean>>,
  selectedInstruction: null as AIInstructionEntry,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSelectedInstruction: ((value: AIInstructionEntry) => undefined) as Dispatch<SetStateAction<AIInstructionEntry>>,
  pid: null as string,
  categoryID: null as string | number,
});

interface Props {
  pid: string;
  categoryID: string | number;
}

export function InstructionContext({ children, pid, categoryID }: PropsWithChildren & Props): ReactElement {
  const [instructionModalOpen, setInstructionModalOpen] = useState<boolean>(false);
  const [selectedInstruction, setSelectedInstruction] = useState<AIInstructionEntry>(null);

  const value = useMemo(() => {
    return {
      instructionModalOpen,
      setInstructionModalOpen,
      selectedInstruction,
      setSelectedInstruction,
      pid,
      categoryID,
    };
  }, [instructionModalOpen, selectedInstruction, pid, categoryID]);

  return <instructionCtx.Provider value={value}>{children}</instructionCtx.Provider>;
}

export function useInstructionContext() {
  const ctx = useContext(instructionCtx);

  return ctx;
}
