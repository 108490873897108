import { useQuery } from '@tanstack/react-query';
import { RetailerType } from 'common/constants/entities';
import { aiPromptsApi } from 'common/services/api/ai-prompts/ai-prompts.service';
import {
  GetSelectModelParams,
  PromptFilters,
  TemplateValuesFilters,
} from 'common/services/api/ai-prompts/ai-prompts.types';
import { userService } from 'common/services/user.service';

export enum AIPromptsQueryKey {
  SelectedModel = 'SelectedModel',
  TemplateValues = 'TemplateValues',
  SelectedPromptVersion = 'SelectedPromptVersion',
  ListPrompts = 'ListPrompts',
}

export function useSelectedModelQuery(params: GetSelectModelParams) {
  const query = useQuery({
    queryKey: [AIPromptsQueryKey.SelectedModel, params],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } =
        retailer === RetailerType.Walmart
          ? await aiPromptsApi.getSelectWalmartModel(params)
          : await aiPromptsApi.getSelectRetailerModel(retailer, params);
      return data;
    },
  });

  return query;
}

export function useTemplateValuesQuery(params: TemplateValuesFilters) {
  const query = useQuery({
    queryKey: [AIPromptsQueryKey.TemplateValues, params],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } =
        retailer === RetailerType.Walmart
          ? await aiPromptsApi.getWalmartTemplateValues(params)
          : await aiPromptsApi.getRetailerTemplateValues(retailer, params);
      return data.templateValues;
    },
  });

  return query;
}

export function useSelectedPromptVersionQuery(params: PromptFilters) {
  const query = useQuery({
    queryKey: [AIPromptsQueryKey.SelectedPromptVersion, params],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } =
        retailer === RetailerType.Walmart
          ? await aiPromptsApi.getWalmartSelectedPrompt(params)
          : await aiPromptsApi.getRetailerSelectedPrompt(retailer, params);
      return data;
    },
  });

  return query;
}

export function useListPromptsQuery(params: PromptFilters) {
  const query = useQuery({
    queryKey: [AIPromptsQueryKey.ListPrompts, params],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } =
        retailer === RetailerType.Walmart
          ? await aiPromptsApi.getWalmartListPrompts(params)
          : await aiPromptsApi.getRetailerListPrompts(retailer, params);
      return data;
    },
  });

  return query;
}
