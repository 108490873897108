import { ReactElement, forwardRef } from 'react';
import { CustomTableContainerProps, TableContainerWithLoading } from './table-container-with-loading';
import { AutoGrowContainer } from '../auto-grow-container';

export const TableContainerWithLoadingGrow = forwardRef<HTMLDivElement, CustomTableContainerProps>(function (
  { children, sx, ...props },
  ref
): ReactElement {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <AutoGrowContainer sx={sx} placeholder={<></>}>
      {({ height }) => (
        <TableContainerWithLoading {...{ ...props, sx: undefined }} maxHeight={height} ref={ref}>
          {children}
        </TableContainerWithLoading>
      )}
    </AutoGrowContainer>
  );
});
