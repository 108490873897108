import { AxiosResponse } from 'axios';
import { ApiService } from 'common/services';
import { ExportResponse, ExtendedContentEntry, ScopeId } from 'common/types/common';
import { ContentStatus, RetailerType, selectAllValue } from 'common/constants/entities';
import { pick } from 'lodash';
import { stringifySort } from 'common/utils/sort';
import {
  AddCostcoBody,
  AddTcinsBody,
  AddWpidsBody,
  AttachProductFlagBody,
  AttributeHistoryParams,
  AttributeHistoryResponse,
  ContentFilters,
  ContentListResponse,
  ContentStatsFilters,
  DryExportBody,
  GetTargetImportStatusResponse,
  GetWalmartImportResponse,
  ImportTargetProductResponse,
  PatchBulkStatusBody,
  PatchProductsStatusBody,
  PatchTargetBody,
  PDPAttributeHistoryResponse,
  ProductSaveBody,
  ResetValueBody,
  SuppliersProducts,
  UploadTargetBody,
  WalmartImportResultParams,
} from './content-api.types';

function getTags(search: Array<string>) {
  const excludedFlags = ['flag:nenr', 'flag:psma', 'flag:invalid', 'flag:example'];
  const filteredTags = search.filter(s => s.includes(':'));
  const hasExcludedFlags = filteredTags.some(tag => excludedFlags.includes(tag));
  const t = filteredTags.join(',').trim();

  if (!t.length) return undefined;
  return hasExcludedFlags ? t : t.replace(':', '@');
}

function getWPIDs(search: Array<string>) {
  const ids = search
    .filter(s => !s.includes(':') && s.length === 12)
    .join(',')
    .trim();
  if (!ids.length) return undefined;
  return ids;
}

function getSKUs(search: Array<string>) {
  const ids = search
    .filter(s => !s.includes(':') && Number.isInteger(Number(s)))
    .join(',')
    .trim();
  if (!ids.length) return undefined;
  return ids;
}

function findName(values: string[]): string {
  const regex = /^\D+$/;

  // eslint-disable-next-line no-restricted-syntax
  for (const str of values) {
    if (regex.test(str)) {
      return str;
    }
  }

  return undefined;
}

function parseContentFilters(args?: ContentFilters) {
  if (!args) return undefined;

  return {
    limit: args.limit,
    offset: args.offset,
    ...(args.sort ? { order: stringifySort(args.sort) } : {}),
    ...(args.category_id ? { category_id: args.category_id } : {}),
    ...(args.wsScope ? { wsScope: args.wsScope } : {}),
    ...(args.assigned_users?.length ? { assigned_users: args.assigned_users.join(',') } : {}),
    ...(args.approved_by?.length ? { approved_by: args.approved_by.join(',') } : {}),
    ...(args.actor_ids?.length ? { actor_ids: args.actor_ids.join(',') } : {}),
    ...(args.statuses?.length ? { statuses: args.statuses.join(',') } : {}),
    ...(args.scope_ids?.length && !args.scope_ids?.includes(selectAllValue)
      ? { scope_ids: args.scope_ids.join(',') }
      : {}),
    ...(args.extended !== undefined ? { extended: true } : {}),
    ...(args.skip_level !== undefined ? { skip_level: args.skip_level } : {}),
    // important to also send empty array in this case
    ...(args.wpIDs !== undefined ? { wpIDs: args.wpIDs.join(',') } : {}),
    ...(args.search?.length
      ? {
          tags: getTags(args.search),
          wpIDs: getWPIDs(args.search),
          skus: getSKUs(args.search),
          ...(findName(args.search) ? { search: findName(args.search) } : {}),
        }
      : {}),
  };
}

function parseContentStatsFilters(args?: ContentStatsFilters) {
  if (!args) return undefined;

  return {
    ...(args.scope_ids?.length && !args.scope_ids?.includes(selectAllValue)
      ? { scope_ids: args.scope_ids.join(',') }
      : {}),
    ...(args?.ws_scope ? { wsScope: args.ws_scope } : {}),
    ...(args?.categoryID ? { categoryIDs: [args.categoryID] } : {}),
    ...(args?.categoryIDs?.length ? { categoryIDs: args.categoryIDs } : {}),
    ...(args?.sub_vendor_id !== undefined ? { sub_vendor_id: args.sub_vendor_id } : {}),
  };
}

class ContentApiService extends ApiService {
  getContent = (filters?: ContentFilters): Promise<AxiosResponse<ContentListResponse>> => {
    return this.get('/content', { params: parseContentFilters(filters) });
  };

  getContentById = (
    id: string
  ): Promise<AxiosResponse<{ product: ExtendedContentEntry; content: ExtendedContentEntry }>> => {
    return this.get(`/content/${id}`);
  };

  importProducts = (productIDs: Array<string>) => {
    return this.post('/content/etl', { productIDs });
  };

  importRetailerProduct = (
    retailer: RetailerType,
    body: AddTcinsBody | AddCostcoBody
  ): Promise<AxiosResponse<ImportTargetProductResponse>> => {
    return this.post(`/vendors/${retailer.toLowerCase()}/products/import`, body);
  };

  importSyndigoProduct = (
    apiRetailer: RetailerType,
    retailer: RetailerType,
    subRetailer: string,
    body: AddTcinsBody,
    files: Array<File>,
    images: Array<File>
  ): Promise<AxiosResponse<ImportTargetProductResponse>> => {
    const formData = new FormData();

    if (files?.length) {
      files?.forEach(p => {
        formData.append('products', p);
      });
    }
    if (images?.length) {
      images?.forEach(p => {
        formData.append('images', p);
      });
    }

    formData.append('subVendorID', subRetailer);

    Object.entries(body).forEach(([key, value]) => {
      if (value === undefined || value === null) {
        return;
      }
      if (Array.isArray(value)) {
        value.forEach(val => formData.append(`${key}[]`, val));
      } else if (typeof value === 'object' && !(value instanceof File)) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, String(value));
      }
    });

    return this.post(`/vendors/${apiRetailer.toLowerCase()}/products/syndigo/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  importWalmartProduct = (body: AddWpidsBody): Promise<AxiosResponse<ImportTargetProductResponse>> => {
    return this.post('/content/import', body);
  };

  searchSuppliersProducts = (itemsIDs: Array<string>): Promise<AxiosResponse<SuppliersProducts>> => {
    return this.post('/suppliers/search/products', { itemsIDs });
  };

  getRetailerImportStatus = (
    retailer: RetailerType,
    id: string
  ): Promise<AxiosResponse<GetTargetImportStatusResponse>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/products/import/${id}/status`);
  };

  getWalmartImportStatus = (id: string): Promise<AxiosResponse<GetTargetImportStatusResponse>> => {
    return this.get(`/content/import/${id}/status`);
  };

  getWalmartImportResult = (args: WalmartImportResultParams): Promise<AxiosResponse<GetWalmartImportResponse>> => {
    return this.get(`/cqrs/result/${args?.id}`, {
      params: {
        ...(args?.includeErrorPayload !== undefined ? { includeErrorPayload: args?.includeErrorPayload } : {}),
      },
    });
  };

  saveProduct = (wpid: string, data: ProductSaveBody): Promise<AxiosResponse<{ product: ExtendedContentEntry }>> => {
    return this.patch(`/content/${wpid}`, data);
  };

  saveRetailerProduct = (
    retailer: RetailerType,
    tcin: string,
    data: ProductSaveBody
  ): Promise<AxiosResponse<{ product: ExtendedContentEntry }>> => {
    return this.patch(`/vendors/${retailer.toLowerCase()}/products/${tcin}/content`, data);
  };

  patchProductsStatus = (retailer: RetailerType, body: PatchProductsStatusBody) => {
    return this.patch(`/vendors/${retailer.toLowerCase()}/products/status`, body);
  };

  patchWalmartProductsStatus = (body: PatchProductsStatusBody) => {
    return this.patch('/content/status', body);
  };

  patchWalmartBulkStatus = (
    body: PatchBulkStatusBody
  ): Promise<AxiosResponse<{ updated: number; matched: number }>> => {
    return this.patch('/content/bulk/status', body);
  };

  attachWalmartFlag = (wpid: string, body: AttachProductFlagBody) => {
    return this.post(`/content/${wpid}/flags`, body);
  };

  attachRetailerFlag = (retailer: RetailerType, tcin: string, body: AttachProductFlagBody) => {
    return this.post(`/vendors/${retailer.toLowerCase()}/products/${tcin}/flags`, body);
  };

  detachWalmartFlags = (wpid: string, flags: Array<string>) => {
    return this.delete(`/content/${wpid}/tags`, { tags: flags });
  };

  detachRetailerFlags = (retailer: RetailerType, tcin: string, flags: Array<string>) => {
    return this.delete(`/vendors/${retailer.toLowerCase()}/products/${tcin}/tags`, { tags: flags });
  };

  getWalmartStats = (
    filters?: ContentStatsFilters
  ): Promise<AxiosResponse<{ stats: Record<ContentStatus, number> }>> => {
    return this.get('/content/stats', { params: parseContentStatsFilters(filters) });
  };

  getStats = (
    retailer: RetailerType,
    filters?: ContentStatsFilters
  ): Promise<AxiosResponse<{ stats: Record<ContentStatus, number> }>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/products/stats`, { params: parseContentStatsFilters(filters) });
  };

  resetValue = (filters: ResetValueBody) => {
    return this.post('/content/reset', filters);
  };

  dryExport = (body: DryExportBody): Promise<AxiosResponse<ExportResponse>> => {
    return this.post('/v2/content/dry-export', body);
  };

  pdpWalmartExport = (body: DryExportBody): Promise<AxiosResponse<ExportResponse>> => {
    return this.post('/v2/content/export', body);
  };

  gsheetExport = (body: DryExportBody): Promise<AxiosResponse<ExportResponse>> => {
    return this.post('/v2/content/gsheet-export', body);
  };

  gsheetScopeExport = (body: DryExportBody, retailer: string): Promise<AxiosResponse<ExportResponse>> => {
    return this.post(`/vendors/${retailer}/products/gsheet-export`, body);
  };

  uploadTargetProducts = (body: UploadTargetBody): Promise<AxiosResponse<ExportResponse>> => {
    return this.post('/vendors/target/products/pt-output', body);
  };

  uploadSyndigoProducts = (body: UploadTargetBody): Promise<AxiosResponse<ExportResponse>> => {
    return this.post('/vendors/target/products/syndigo-export', body);
  };

  getRetailerTags = (retailer: string): Promise<AxiosResponse<Array<string>>> => {
    return this.get(`/vendors/${retailer}/tags/list`);
  };

  getTagsList = (): Promise<AxiosResponse<Array<string>>> => {
    return this.get('/tags');
  };

  patchTargetScope = (data: { id: ScopeId; body: PatchTargetBody }, retailer: string) => {
    return this.patch(`/vendors/${retailer}/scopes/${data?.id}`, data?.body);
  };

  getAttributeHistory = (params: AttributeHistoryParams): Promise<AxiosResponse<AttributeHistoryResponse>> => {
    return this.get(`/content/${params.pid}/ai-history/attributes`, { params: pick(params, 'attributeName') });
  };

  getVendorAttributeHistory = (
    retailer: string,
    params: AttributeHistoryParams
  ): Promise<AxiosResponse<AttributeHistoryResponse>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/products/${params.pid}/ai-history/attributes`, {
      params: pick(params, 'attributeName'),
    });
  };

  getPDPAttributeHistory = (params: AttributeHistoryParams): Promise<AxiosResponse<PDPAttributeHistoryResponse>> => {
    return this.get(`/content/${params.pid}/ai-history/pdp`, { params: pick(params, 'attributeName') });
  };

  getPDPVendorAttributeHistory = (
    retailer: string,
    params: AttributeHistoryParams
  ): Promise<AxiosResponse<PDPAttributeHistoryResponse>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/products/${params.pid}/ai-history/pdp`, {
      params: pick(params, 'attributeName'),
    });
  };
}

export const contentApi = new ContentApiService(process.env.REACT_APP_AI_API);
