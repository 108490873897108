import * as Yup from 'yup';

export const instructionsValidationSchema = Yup.object().shape<
  Record<keyof AIModule.Conditions.InstructionForm, Yup.AnySchema>
>({
  id: Yup.mixed(),
  name: Yup.string().required().label('Name'),
  instruction: Yup.string().required().label('Instruction'),
  expressions: Yup.array().of(
    Yup.object().shape({
      conditions: Yup.array().of(
        Yup.object().shape({
          field: Yup.string().required().label('Field'),
          operator: Yup.string().required().label('Operator'),
          value: Yup.mixed()
            .label('Value')
            .when('operator', {
              is: (operator: string) => operator === 'in',
              then: Yup.array().of(Yup.string()).min(1).required(),
              otherwise: Yup.string().required(),
            }),
        })
      ),
    })
  ),
});
