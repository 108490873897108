import { RetailerType } from 'common/constants/entities';
import { userService } from 'common/services/user.service';

export const getProductIDKey = () => {
  switch (userService.getRetailer()) {
    case RetailerType.Walmart:
    case RetailerType.Flywheel:
      return 'pid' as const;
    case RetailerType.Target:
    case RetailerType.Instacart:
    case RetailerType.SyndigoWalmart:
    case RetailerType.SyndigoAmazon:
    case RetailerType.SyndigoKroger:
    case RetailerType.SyndigoTarget:
    case RetailerType.Autozone:
    case RetailerType.Amazon:
    case RetailerType.Costco:
    case RetailerType.Suplery:
      return 'tcin' as const;
    default:
      return null;
  }
};

export const getCategoryIDKeyFromProduct = () => {
  switch (userService.getRetailer()) {
    case RetailerType.Walmart:
    case RetailerType.Flywheel:
      return 'category_id' as const;
    case RetailerType.Target:
    case RetailerType.Instacart:
    case RetailerType.SyndigoWalmart:
    case RetailerType.SyndigoAmazon:
    case RetailerType.SyndigoKroger:
    case RetailerType.SyndigoTarget:
    case RetailerType.Autozone:
    case RetailerType.Amazon:
    case RetailerType.Costco:
    case RetailerType.Suplery:
      return 'item_type' as const;
    default:
      return null;
  }
};

export const getProductIDColumnName = () => {
  return 'Product ID';

  // switch (userService.getRetailer()) {
  //   case RetailerType.Walmart:
  //     return 'WPID/item_id';
  //   case RetailerType.Target:
  //     return 'TCIN';
  //   case RetailerType.Instacart:
  //     return 'UPC';
  //   case RetailerType.Amazon:
  //     return 'ASIN';
  //   case RetailerType.Costco:
  //   case RetailerType.SyndigoWalmart:
  //   case RetailerType.SyndigoAmazon:
  //   case RetailerType.SyndigoKroger:
  //   case RetailerType.SyndigoTarget:
  //   case RetailerType.Autozone:
  //   case RetailerType.Flywheel:
  //   case RetailerType.Suplery:
  //     return 'ID';
  //   default:
  //     return null;
  // }
};
