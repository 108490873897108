import { Box, CircularProgress, Paper, SxProps, TableContainer, TableContainerProps } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { Styles } from 'common/types/styles';
import { ReactElement, forwardRef } from 'react';

interface StylesProps {
  loading: boolean;
  round: boolean;
  minHeight?: number | string;
  maxHeight?: number | string;
}

const getStyles = ({ loading, round, minHeight, maxHeight }: StylesProps): Styles => ({
  coverLoadingContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    zIndex: 10,
  },
  nextLoadingContainer: {
    width: '100%',
    height: 96,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  withBlur: {
    filter: loading ? 'blur(1px)' : 'none',
    overflowX: 'scroll',
    position: 'relative',
    height: '100%',
    maxHeight: 'inherit',
  },
  table: {
    minHeight: minHeight || 240,
    maxHeight,
    height: maxHeight ? '100%' : 'unset',
    position: 'relative',
    borderRadius: round ? '5px' : 0,
    boxShadow: 'none',
  },
});

export interface CustomTableContainerProps extends TableContainerProps {
  loading?: boolean;
  nextPageLoading?: boolean;
  round?: boolean;
  minHeight?: number | string;
  maxHeight?: number | string;
  sx?: SystemStyleObject;
}

export const TableContainerWithLoading = forwardRef<HTMLDivElement, CustomTableContainerProps>(function (
  { loading = false, sx, children, round = true, minHeight, maxHeight, nextPageLoading, ...props },
  ref
): ReactElement {
  const styles = getStyles({ loading: !nextPageLoading && loading, round, minHeight, maxHeight });

  return (
    <>
      <TableContainer component={Paper} sx={{ ...styles.table, ...sx } as SxProps} ref={ref} {...props}>
        <Box sx={styles.withBlur}>{children}</Box>
        {!nextPageLoading && loading && (
          <Box sx={styles.coverLoadingContainer}>
            <CircularProgress color="primary" />
          </Box>
        )}
      </TableContainer>
      {nextPageLoading && (
        <Box sx={styles.nextLoadingContainer}>
          <CircularProgress color="primary" />
        </Box>
      )}
    </>
  );
});
