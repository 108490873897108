import { Box, Skeleton } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { useWindowSize } from 'common/hooks';
import { Styles } from 'common/types/styles';
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';

const styles: Styles = {
  container: { flexGrow: 1 },
  loader: { height: '100%', minHeight: 20 },
};

interface Props {
  children: (props: { height?: number; width?: number }) => ReactNode;
  placeholder?: ReactNode;
  sx?: SystemStyleObject;
}

export function AutoGrowContainer({ children, placeholder, sx }: Props): ReactElement {
  const ref = useRef<HTMLDivElement>();

  const [size, setSize] = useState({ width: 0, height: 0 });
  const [calculating, setCalculating] = useState<boolean>(true);
  const [initialized, setInitialized] = useState<boolean>(false);

  const windowSize = useWindowSize();

  useEffect(() => {
    setSize({ width: ref.current.clientWidth, height: ref.current.clientHeight });
    setCalculating(false);
    if (!initialized) setInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculating]);

  useEffect(() => {
    if (!initialized) return;
    setCalculating(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.height, windowSize.width]);

  return (
    <Box sx={[styles.container, sx]} ref={ref}>
      {calculating ? placeholder || <Skeleton variant="rounded" sx={styles.loader} /> : children(size)}
    </Box>
  );
}
