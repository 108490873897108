export function compareArrays<T>(array1: T[], array2: T[]): boolean {
  if (array1.length !== array2.length) {
    return false;
  }

  const array1Count = new Map<T, number>();
  const array2Count = new Map<T, number>();

  array1.forEach(value => {
    const count = array1Count.get(value) || 0;
    array1Count.set(value, count + 1);
  });

  if (array2?.length) {
    array2.forEach(value => {
      const count = array2Count.get(value) || 0;
      array2Count.set(value, count + 1);
    });
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const [value, count] of array1Count) {
    if (count !== (array2Count.get(value) || 0)) {
      return false;
    }
  }

  return true;
}

export function splitStringToArray(value: string, separators = [';']) {
  if (!value) return [];
  const separatorsRegex = new RegExp(`[${separators.join('')}]`);
  const result = value
    .split(separatorsRegex)
    .map(word => word.trim())
    .filter(Boolean);
  return result;
}

export function findFirstUniqueIndex(filter: number[], N: number): number | null {
  const generatedArray = Array.from({ length: N + 1 }, (_, index) => index);

  // eslint-disable-next-line no-restricted-syntax
  for (const num of generatedArray) {
    if (!filter.includes(num)) {
      return num;
    }
  }

  return null; // If all values in the range are present in the array
}

// predefined or ai values for some reason return plain arrays instead of joined by ";"
export function extractBuggyAttributeValue(value: string | string[] | object, separator = ';'): string {
  let parsedJSON;

  if (!value) return null;
  if (typeof value === 'object' && !Array.isArray(value)) return JSON.stringify(value);
  if (typeof value === 'string') {
    try {
      // eslint-disable-next-line quotes
      parsedJSON = JSON.parse(value.replace(/'/g, '"'));
      // eslint-disable-next-line no-empty
    } catch {}
  }

  const v = parsedJSON ?? value;

  if (!Array.isArray(v)) return v ? String(v) : v;
  return v.map(item => (typeof item === 'object' ? JSON.stringify(item) : item)).join(separator);
}

export function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i -= 1) {
    // Generate a random index between 0 and i (inclusive)
    const j = Math.floor(Math.random() * (i + 1));

    // Swap elements array[i] and array[j]
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
