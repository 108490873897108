import { Box, Button } from '@mui/material';
import { Styles } from 'common/types/styles';
import { Modal } from 'common/ui/containers/modal';
import { ReactElement, useEffect, useState } from 'react';
import { KWInput } from './kw-input';

const styles: Styles = {
  btns: { display: 'flex', gap: 2, mt: 2 },
  btn: { width: 100 },
};

interface Props {
  open: boolean;
  onClose: () => void;
  onAddKW: (kw: string) => void;
  kws: string[];
}

export function NewKWModal({ open, onClose, onAddKW, kws }: Props): ReactElement {
  const [kw, setKW] = useState<string>('');

  useEffect(() => {
    if (open) return;
    setKW('');
  }, [open]);

  return (
    <Modal open={open} onClose={onClose} title="Add Keyword">
      <KWInput kw={kw} onChange={setKW} kws={[...kws, kw]} disabled={false} />
      <Box sx={styles.btns}>
        <Button variant="contained" sx={styles.btn} onClick={() => onAddKW(kw)}>
          Add
        </Button>
        <Button variant="outlined" sx={styles.btn}>
          Cancel
        </Button>
      </Box>
    </Modal>
  );
}
