/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AllVendorsTitleMap,
  PocRetailers,
  RetailerType,
  RouteRetailersMap,
  SyndigoRetailers,
  WalmartRetailers,
} from 'common/constants/entities';
import { useMeQuery } from 'common/hooks/api/queries/use-users-query';
import { useCurrrentRoute } from 'common/hooks/use-current-route';
import { userService } from 'common/services/user.service';
import {
  Dispatch,
  PropsWithChildren,
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { UnavailableView } from './unavailable-view';

interface IRetailerCtx {
  retailer: RetailerType;
  availableRetailers: string[];
  unavailable: boolean;
  setRetailer: Dispatch<RetailerType>;
}

export const retailerCtx = createContext<IRetailerCtx>({
  retailer: null,
  availableRetailers: [],
  unavailable: false,
  setRetailer: (value: RetailerType) => undefined,
});

const retailers = [
  ...WalmartRetailers,
  RetailerType.Target,
  RetailerType.Instacart,
  RetailerType.Autozone,
  ...PocRetailers,
  ...SyndigoRetailers,
];

export function RetailerCtx({ children }: PropsWithChildren): ReactElement {
  const { isProtectedRoute, route } = useCurrrentRoute();

  const { data: meData } = useMeQuery(Boolean(isProtectedRoute));

  const [retailer, setRetailer] = useState<RetailerType>(RetailerType.Walmart);
  const [fetchedRetailer, setFetchedRetailer] = useState<boolean>(false);

  const availableRetailers = useMemo(() => {
    return (
      userService.isAdmin() || !isProtectedRoute
        ? retailers
        : meData?.vendors?.map(item => AllVendorsTitleMap[item?.name as RetailerType]?.value) ?? []
    ).filter(r =>
      RouteRetailersMap[route?.path] ? RouteRetailersMap[route?.path]?.includes(r as RetailerType) : true
    );
  }, [isProtectedRoute, meData?.vendors, route?.path]);

  const defaultRetailer = availableRetailers?.includes(localStorage.getItem('retailer'))
    ? localStorage.getItem('retailer')
    : availableRetailers[0] ?? null;

  const unavailable = meData ? !availableRetailers.length : false;

  useEffect(() => {
    if (!fetchedRetailer && defaultRetailer) {
      localStorage.setItem('retailer', defaultRetailer);
      setRetailer(defaultRetailer as RetailerType);
      setFetchedRetailer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedRetailer, defaultRetailer]);

  const value = useMemo(() => {
    return {
      retailer,
      availableRetailers,
      unavailable,
      setRetailer,
    };
  }, [retailer, unavailable, availableRetailers]);

  if (unavailable) return <UnavailableView />;

  return <retailerCtx.Provider value={value}>{children}</retailerCtx.Provider>;
}

export function useRetailerContext() {
  const ctx = useContext(retailerCtx);

  return ctx;
}
