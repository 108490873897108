import { Box, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types/styles';
import { CSSProperties, PropsWithChildren, ReactElement, ReactNode } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { LightTooltip } from 'common/ui/containers/light-tooltip';

const styles: Styles = {
  container: { display: 'flex', gap: 1 },
  label: { color: '#7F8299', fontSize: 12 },
  error: { fontSize: 12, color: theme.palette.error.main },
  labelContainer: { display: 'flex', alignItems: 'center', gap: 0.5 },
  tooltip: { width: 16, cursor: 'pointer' },
};

interface Props {
  label: ReactNode;
  sx?: SystemStyleObject;
  direction?: 'row' | 'column';
  style?: CSSProperties;
  error?: string;
  tooltip?: string;
}

export function FieldLabelWrapper({
  label,
  children,
  sx,
  direction = 'column',
  style,
  error,
  tooltip,
}: Props & PropsWithChildren): ReactElement {
  return (
    <Box sx={[styles.container, { flexDirection: direction }, sx]} style={style}>
      <Box sx={styles.labelContainer}>
        {typeof label === 'string' ? <Typography sx={styles.label}>{label}</Typography> : label}
        {tooltip && (
          <LightTooltip placement="right" title={tooltip}>
            <InfoIcon sx={styles.tooltip} color="action" />
          </LightTooltip>
        )}
      </Box>
      {children}
      {error && <Typography sx={styles.error}>{error}</Typography>}
    </Box>
  );
}
