import { ReactElement, useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Styles } from 'common/types/styles';
import { userService } from 'common/services/user.service';
import { PdpAttribute, PdpAttributeData } from 'common/types/common';
import { theme } from 'common/constants/theme';
import { PdpHistoryItem } from './pdp-history-item';

const styles: Styles = {
  tableContainer: { width: '100%' },
  descriptionContainer: { display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 1 },
  descriptionColumn: { width: 250 },
  noItems: { fontSize: 12, color: theme.palette.grey[700], margin: '0 auto', display: 'block', width: 'max-content' },
};

interface Props {
  pdpAttributes: PdpAttributeData | unknown;
}

export function PdpHistoryContent({ pdpAttributes }: Props): ReactElement {
  const isL3 = userService.ensureLevel('L3');

  const history: Array<PdpAttribute> = useMemo(() => {
    return Object.entries(pdpAttributes ?? {}).flatMap(([date, items]) =>
      items?.map((item: PdpAttributeData) => ({ ...item, date }))
    );
  }, [pdpAttributes]);

  const getParsedDates = (date: string) => {
    const [day, month, yearTime] = date.split('/');
    const [year, time] = yearTime.split(', ');
    const [hour, minute, second] = time.split(':');
    return new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute), Number(second));
  };

  const compareDates = (a: PdpAttribute, b: PdpAttribute) => {
    const dateA = getParsedDates(a.date);
    const dateB = getParsedDates(b.date);
    return Number(dateB) - Number(dateA);
  };

  return (
    <TableContainer sx={styles.tableContainer}>
      {history?.length && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Process</TableCell>
              <TableCell>Model</TableCell>
              {!isL3 && <TableCell>Cost</TableCell>}
              <TableCell>Request</TableCell>
              <TableCell>Response</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history?.sort(compareDates)?.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <PdpHistoryItem key={i} item={item} />
            ))}
          </TableBody>
        </Table>
      )}
      {!history?.length && <Typography sx={styles.noItems}>No history</Typography>}
    </TableContainer>
  );
}
