import { useQuery } from '@tanstack/react-query';
import { RetailerType } from 'common/constants/entities';
import { contentApi } from 'common/services/api/content/content-api.service';
import {
  AttributeHistoryParams,
  ContentFilters,
  ContentStatsFilters,
} from 'common/services/api/content/content-api.types';
import { productsApi } from 'common/services/api/products/products-api.service';
import { userService } from 'common/services/user.service';

export enum ContentQueryKey {
  Content = 'Content',
  RandomContent = 'RandomContent',
  ContentList = 'ContentList',
  ContentStats = 'ContentStats',
  TargetImportStatus = 'TargetImportStatus',
  RetailerTags = 'RetailerTags',
  AttributeHistory = 'AttributeHistory',
  PDPAttributeHistory = 'PDPAttributeHistory',
}

interface UseContentProps {
  pid: string;
  enabled?: boolean;
  retailer?: RetailerType;
}

export function useContentQuery({ pid, enabled = true, retailer }: UseContentProps) {
  const query = useQuery({
    queryKey: [ContentQueryKey.Content, pid],
    enabled: Boolean(pid && pid !== 'create') && enabled,
    staleTime: 1000 * 10,
    queryFn: async () => {
      const apiRetailer = retailer ?? (userService.getAPIRetailer() as RetailerType);
      const { data } =
        apiRetailer === RetailerType.Walmart
          ? await contentApi.getContentById(pid)
          : await productsApi.getProductById(apiRetailer, pid);
      return data.product;
    },
  });
  return query;
}

export function useRandomContentQuery() {
  const query = useQuery({
    queryKey: [ContentQueryKey.RandomContent],
    staleTime: 1000 * 10,
    queryFn: async () => {
      const pids = [
        '2A0WRX9H5GQD',
        '7AMBHJ41C6KO',
        '1247G5LOUSYD',
        '2FBQIV78PHXJ',
        '0UWMEHMF1NDM',
        '16EA2GV064D1',
        '59OC6OO2G32H',
        '5KZ9XH4Q8TE6',
      ];

      const randomIndex = Math.floor(Math.random() * pids.length);

      const { data } = await contentApi.getContentById(pids[randomIndex]);

      return data.product;
    },
  });
  return query;
}

export function useContentListQuery(args?: ContentFilters, enabled = true) {
  const query = useQuery({
    queryKey: [ContentQueryKey.ContentList, args],
    enabled,
    queryFn: async () => {
      const { data } = await contentApi.getContent(args);
      return data;
    },
    keepPreviousData: true,
  });
  return query;
}

export function useContentStatsQuery(args?: ContentStatsFilters, enabled = true) {
  const query = useQuery({
    queryKey: [ContentQueryKey.ContentStats, args],
    enabled,
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = userService.ensureRetailer(RetailerType.Walmart)
        ? await contentApi.getWalmartStats(args)
        : await contentApi.getStats(retailer, args);
      return data;
    },
    staleTime: 1000 * 60 * 5,
    keepPreviousData: true,
  });

  return query;
}

export function useContentRetailerTags(enabled = true) {
  const query = useQuery({
    queryKey: [ContentQueryKey.RetailerTags],
    enabled,
    queryFn: async () => {
      const retailer = userService.getAPIRetailer().toLowerCase();
      const { data } = userService.ensureRetailer(RetailerType.Walmart)
        ? await contentApi.getTagsList()
        : await contentApi.getRetailerTags(retailer);
      return data;
    },
    keepPreviousData: true,
  });

  return query;
}

export function useAttributeHistoryQuery(params: AttributeHistoryParams, enabled = true) {
  const query = useQuery({
    queryKey: [ContentQueryKey.AttributeHistory, params],
    enabled,
    queryFn: async () => {
      const retailer = userService.getAPIRetailer().toLowerCase();
      const { data } = userService.ensureRetailers([RetailerType.Walmart])
        ? await contentApi.getAttributeHistory(params)
        : await contentApi.getVendorAttributeHistory(retailer, params);
      return data;
    },
  });

  return query;
}

export function usePDPAttributeHistoryQuery(params: AttributeHistoryParams, enabled = true) {
  const query = useQuery({
    queryKey: [ContentQueryKey.PDPAttributeHistory, params],
    enabled,
    queryFn: async () => {
      const retailer = userService.getAPIRetailer().toLowerCase();
      const { data } = userService.ensureRetailers([RetailerType.Walmart])
        ? await contentApi.getPDPAttributeHistory(params)
        : await contentApi.getPDPVendorAttributeHistory(retailer, params);
      return data;
    },
  });

  return query;
}
