import {
  useConditionalInstructionsQuery,
  useProductSpecialInstructionsQuery,
} from 'common/hooks/api/queries/use-attributes-query';
import { usePagination } from 'common/hooks/use-pagination';
import { AIInstructionEntry, IPagination } from 'common/types/common';
import {
  Dispatch,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useDebounce } from 'use-debounce';

export enum TabEntry {
  ALL = 0,
  PID,
}

export const tableCtx = createContext({
  instructions: [] as AIInstructionEntry[],
  pagination: null as ReturnType<typeof usePagination> & { total: number },
  loading: false,
  tab: TabEntry.PID,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setTab: ((value: TabEntry) => undefined) as Dispatch<SetStateAction<TabEntry>>,
});

interface Props {
  pid: string;
}

export function TableContext({ children, pid }: PropsWithChildren & Props): ReactElement {
  const { limit, page, setLimit, setPage } = usePagination();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const memoisedFilters: IPagination = useMemo(() => {
    return {
      limit,
      offset: page * limit,
    };
  }, [limit, page]);

  const [tab, setTab] = useState<TabEntry>(TabEntry.PID);

  const [filters] = useDebounce<IPagination>(memoisedFilters, 300);

  const { data: productInstructionsData, isFetching: isProductInstructionFetching } =
    useProductSpecialInstructionsQuery(pid, tab === TabEntry.PID);
  const { data: globalInstructions, isFetching: isGlobalInstructionsFetching } = useConditionalInstructionsQuery(
    filters,
    tab === TabEntry.ALL
  );

  const instructions = useMemo(() => {
    return (
      (tab === TabEntry.ALL
        ? globalInstructions?.conditionalInstructions
        : productInstructionsData?.conditionalInstructions) ?? []
    );
  }, [globalInstructions?.conditionalInstructions, productInstructionsData?.conditionalInstructions, tab]);

  const value = useMemo(() => {
    return {
      instructions,
      pagination: {
        limit,
        page,
        setLimit,
        setPage,
        total: globalInstructions?.total ?? 0,
      },
      tab,
      setTab,
      loading: isProductInstructionFetching || isGlobalInstructionsFetching,
    };
  }, [
    instructions,
    limit,
    page,
    setLimit,
    setPage,
    globalInstructions?.total,
    tab,
    isProductInstructionFetching,
    isGlobalInstructionsFetching,
  ]);

  return <tableCtx.Provider value={value}>{children}</tableCtx.Provider>;
}

export function useTableContext() {
  const ctx = useContext(tableCtx);

  return ctx;
}
