import { useActionNotificationsListQuery } from 'common/hooks/api/queries/use-action-notifications-query';
import { ActionNotification, ActionNotificationStatus } from 'common/types/common';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export const LCOwnActionsKey = '__ownactions';
export const LCLastOpenedKey = '__notifications_open_date';

interface Ctx {
  notifications: ActionNotification[];
  queryLoading: boolean;
  globalLoading: boolean;
  toggleOwnActions: () => void;
  ownActions: boolean;
  lastOpened: string | null;
  setLastOpened: Dispatch<SetStateAction<string | null>>;
  loaded: boolean;
}

const notificationsCtx = createContext<Ctx>({
  notifications: [],
  queryLoading: false,
  globalLoading: false,
  toggleOwnActions: null,
  ownActions: false,
  lastOpened: null,
  setLastOpened: null,
  loaded: false,
});

export function NotificationsContext({ children }: PropsWithChildren): ReactElement {
  const [globalLoading, setGlobalLoading] = useState<boolean>(false);
  const [ownActions, setOwnActions] = useState<boolean>(localStorage.getItem(LCOwnActionsKey) === 'true');
  const [lastOpened, setLastOpened] = useState<string | null>(localStorage.getItem(LCLastOpenedKey) ?? null);

  const { data, isFetching } = useActionNotificationsListQuery(
    {
      limit: 150,
      offset: 0,
      ownActions,
    },
    globalLoading ? 1000 * 5 : 1000 * 60 * 5
  );

  const toggleOwnActions = useCallback(() => {
    const newValue = !ownActions;
    setOwnActions(newValue);
    localStorage.setItem(LCOwnActionsKey, String(newValue));
  }, [ownActions]);

  const value = useMemo<Ctx>(() => {
    return {
      notifications: data?.notifications ?? [],
      globalLoading,
      queryLoading: isFetching,
      toggleOwnActions,
      ownActions,
      lastOpened,
      loaded: Boolean(data),
      setLastOpened,
    };
  }, [data, globalLoading, isFetching, lastOpened, ownActions, toggleOwnActions]);

  useEffect(() => {
    if (!data) return;
    setGlobalLoading(data.notifications.some(n => n.status === ActionNotificationStatus.IN_PROGRESS));
  }, [data]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <notificationsCtx.Provider value={value}>{children}</notificationsCtx.Provider>;
}

export function useNotificationsContext() {
  const ctx = useContext(notificationsCtx);
  return ctx;
}
