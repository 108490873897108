import { ChangeEvent, MouseEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { Styles } from 'common/types/styles';
import { FieldLabelWrapper } from 'common/ui/inputs/field-label-wrapper';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import SettingsIcon from '@mui/icons-material/Settings';
import { SelectField, SelectFieldOption } from 'common/ui/inputs/select-field';
import { ActionsOption, ActionsPopover } from 'common/ui/inputs/actions-popover';
import { AIModelsSelect } from 'common/ui/inputs/ai-models-select';
import { ButtonWithLoading } from 'common/ui/containers/button-with-loading';
import { userService } from 'common/services/user.service';
import { PDPType } from 'common/types/common';
import { format } from 'date-fns';
import {
  useCreatePromptMutation,
  useSelectModelMutation,
  useSelectVersionMutation,
} from 'common/hooks/api/mutations/use-ai-prompts-mutation';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useQueryClient } from '@tanstack/react-query';
import {
  AIPromptsQueryKey,
  useListPromptsQuery,
  useSelectedModelQuery,
  useSelectedPromptVersionQuery,
  useTemplateValuesQuery,
} from 'common/hooks/api/queries/use-ai-prompts-query';
import { ProductAITabValue } from './constants';

const getGenerationType = (value: string) => {
  switch (value) {
    case 'attribute_generation':
      return 1;
    case 'optimized_pdp_generation_system':
    case 'optimized_pdp_generation':
      return 5;
    case 'seo_optimization_system':
    case 'seo_optimization':
      return 6;
    case 'optimized_product_title_generation_system':
    case 'optimized_product_title_generation':
      return 7;
    case 'optimized_product_short_description_generation_system':
    case 'optimized_product_short_description_generation':
      return 8;
    case 'optimized_product_long_description_generation_system':
    case 'optimized_product_long_description_generation':
      return 9;
    default:
      return 1;
  }
};

const getPromptType = (value: string) => {
  switch (value) {
    case 'system':
      return 2;
    default:
      return 1;
  }
};

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', gap: 2, mb: 2, pb: 2, borderBottom: '1px solid #7f82992a' },
  head: { display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' },
  modelSelect: { flexGrow: 1 },
  templateSelect: { width: 250 },
  typeSelect: { width: 250 },
  templateSelectContainer: { display: 'flex', alignItems: 'center', gap: 0.5 },
  btn: { flexGrow: 1 },
  label: { color: '#7F8299', fontSize: 12 },
  labelWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

interface Props {
  selectedTab: ProductAITabValue;
  attributeKey?: string;
  pid: string;
  type: PDPType;
}

export function TemplateType({ selectedTab, attributeKey, pid, type }: Props): ReactElement {
  const [prompt, setPrompt] = useState<string>('');
  const [template, setTemplate] = useState<string>('');
  const [selectedModel, setSelectedModel] = useState<string>('');
  const [placeholdersAnchorEl, setPlaceholdersAnchorEl] = useState<HTMLButtonElement>();
  const [selectedTemplateID, setSelectedTemplateID] = useState<number | string>();
  const [defaultTemplateID, setDefaultTemplateID] = useState<number | string>();

  const queryClient = useQueryClient();

  const { data: placeholders, isFetching: isPlaceholdersFetching } = useTemplateValuesQuery({
    generation_type: getGenerationType(type?.value),
    product_id: pid,
    ...(getGenerationType(type?.value) === 1 ? { attribute_name: attributeKey } : {}),
    ...(userService.getSubRetailer() ? { sub_vendor_id: userService.getSubRetailer() } : {}),
  });

  const { data: selectedModelData, isFetching: isSelectedModelDataFetching } = useSelectedModelQuery({
    generation_type: getGenerationType(type?.value),
    ...(userService.getSubRetailer() ? { sub_vendor_id: userService.getSubRetailer() } : {}),
  });

  const {
    data: templatesData,
    isFetched: isFetchedTemplates,
    refetch,
  } = useListPromptsQuery({
    generation_type: getGenerationType(type?.value),
    prompt_type: getPromptType(type.promptType),
    ...(userService.getSubRetailer() ? { sub_vendor_id: userService.getSubRetailer() } : {}),
  });

  const {
    data: selectedTemplatesData,
    isFetched: isFetchedSelectedTemplate,
    refetch: refetchSelectedTemplate,
  } = useSelectedPromptVersionQuery({
    generation_type: getGenerationType(type?.value),
    prompt_type: getPromptType(type.promptType),
    ...(userService.getSubRetailer() ? { sub_vendor_id: userService.getSubRetailer() } : {}),
  });

  const { mutate: mutateSavePromptTemplate, isLoading: isSavePromptTemplateLoading } = useCreatePromptMutation();
  const { mutate: mutateDefaultTemplate } = useSelectVersionMutation();
  const { mutate: mutateSaveModel, isLoading: isSaveModelLoading } = useSelectModelMutation();

  const placeholdersOptions: Array<ActionsOption> = useMemo(() => {
    return Object.keys(placeholders ?? {}).map(key => ({
      name: key,
      value: key,
    }));
  }, [placeholders]);

  const templatesOptions: Array<SelectFieldOption> = useMemo(() => {
    return (templatesData?.items ?? []).map(t => ({
      label: `ver.${t?.version} ${format(new Date(t?.created_at), 'yyyy-MM-dd')}`,
      value: t.id,
    }));
  }, [templatesData?.items]);

  const handlePlaceholdersOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setPlaceholdersAnchorEl(e.currentTarget);
  };

  const handlePlaceholdersClose = () => {
    setPlaceholdersAnchorEl(null);
  };

  const handleTemplateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTemplate(e.target.value);
  };

  const handleSelectModel = (value: string) => {
    setSelectedModel(value);
  };

  const handleSelectedModelChange = (value: string) => {
    handleSelectModel(value);
  };

  const handleTemplateSave = () => {
    mutateSavePromptTemplate(
      {
        prompt: template,
        generationType: getGenerationType(type?.value),
        promptType: getPromptType(type.promptType),
        ...(userService.getSubRetailer() ? { subVendorId: userService.getSubRetailer() } : {}),
      },
      {
        onSuccess: () => {
          toast.success(`${type?.displayName} template saved succesfully`);
          refetch();
          refetchSelectedTemplate();
        },
      }
    );
  };

  const handleInvalidateQueries = () => {
    [AIPromptsQueryKey.SelectedModel, AIPromptsQueryKey.ListPrompts].forEach(q => queryClient.invalidateQueries([q]));
  };

  const handleSetDefaultTemplate = () => {
    if (selectedTemplateID === defaultTemplateID || selectedTemplateID === 'default') return;

    setDefaultTemplateID(selectedTemplateID);
    mutateDefaultTemplate(
      {
        id: selectedTemplateID as number,
        generationType: getGenerationType(type?.value),
        promptType: getPromptType(type.promptType),
      },
      {
        onSuccess: () => {
          toast.success(`${type?.displayName} default template changed succesfully`);
          refetchSelectedTemplate();
        },
      }
    );
  };

  const handleModelSave = () => {
    mutateSaveModel(
      {
        model: selectedModel,
        generationType: getGenerationType(type?.value),
        ...(userService.getSubRetailer() ? { subVendorId: userService.getSubRetailer() } : {}),
      },
      {
        onSuccess: () => {
          toast.success('AI Model saved succesfully');
          handleInvalidateQueries();
        },
      }
    );
  };

  useEffect(() => {
    if (!isFetchedTemplates || !isFetchedSelectedTemplate) return;

    const selectedTemplate = templatesOptions?.find(item => item?.value === selectedTemplatesData?.selected?.id)
      ? selectedTemplatesData?.selected?.id
      : null;

    const templatesDataLastIndex = templatesData?.items?.length ? templatesData.items.length - 1 : 0;
    const defaultTemplate = templatesData?.items?.[templatesDataLastIndex]?.id;
    setSelectedTemplateID(selectedTemplate ?? defaultTemplate ?? 'default');
  }, [
    isFetchedSelectedTemplate,
    isFetchedTemplates,
    selectedTemplatesData?.selected?.id,
    templatesData?.items,
    templatesOptions,
  ]);

  useEffect(() => {
    if (!selectedTemplateID) return;

    const defaultTemplate = templatesData?.items?.[0]?.prompt;

    if (selectedTemplateID === 'default') setTemplate(defaultTemplate);
    else setTemplate((templatesData?.items ?? []).find(t => t.id === selectedTemplateID)?.prompt || '');
  }, [selectedTemplateID, templatesData?.items]);

  useEffect(() => {
    if (!placeholders) return;

    let prompt = template;

    Object.entries(placeholders).forEach(([key, value]) => {
      prompt = prompt?.replace(new RegExp(`{${key}}`, 'g'), value);
    });

    setPrompt(prompt);
  }, [template, placeholders]);

  useEffect(() => {
    if (!isFetchedTemplates || !isFetchedSelectedTemplate) return;
    setDefaultTemplateID(selectedTemplatesData?.selected?.id);
  }, [isFetchedSelectedTemplate, isFetchedTemplates, selectedTemplatesData]);

  useEffect(() => {
    if (!selectedModelData?.selected?.model || isSelectedModelDataFetching) return;
    setSelectedModel(selectedModelData?.selected?.model);
  }, [selectedModelData?.selected, isSelectedModelDataFetching]);

  return (
    <>
      <Box sx={styles.container}>
        <Typography>{type?.displayName}</Typography>
        <Box sx={styles.head}>
          {selectedTemplateID && isFetchedTemplates && (
            <FieldLabelWrapper label="Template Version">
              <Box sx={styles.templateSelectContainer}>
                <SelectField
                  options={templatesOptions}
                  value={selectedTemplateID}
                  onChange={setSelectedTemplateID}
                  sx={styles.templateSelect}
                />
                {selectedTemplateID !== 'default' && (
                  <LightTooltip
                    title={selectedTemplateID === defaultTemplateID ? 'Default Template' : 'Set as Default'}
                    placement="right"
                  >
                    <IconButton onClick={handleSetDefaultTemplate}>
                      {selectedTemplateID === defaultTemplateID ? (
                        <CheckBoxIcon color="success" />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </IconButton>
                  </LightTooltip>
                )}
              </Box>
            </FieldLabelWrapper>
          )}

          {selectedTab === ProductAITabValue.Template && (
            <Box>
              <LightTooltip title="Copy Placeholder" placement="left">
                <IconButton onClick={handlePlaceholdersOpen}>
                  <SettingsIcon fontSize="small" />
                </IconButton>
              </LightTooltip>
            </Box>
          )}
        </Box>

        {selectedTab === ProductAITabValue.Template && (
          <TextField
            value={template}
            onChange={handleTemplateChange}
            maxRows={6}
            minRows={6}
            InputLabelProps={{ shrink: true }}
            disabled={isPlaceholdersFetching}
            fullWidth
            multiline
          />
        )}

        {selectedTab === ProductAITabValue.Prompt && (
          <TextField value={prompt} maxRows={6} minRows={6} InputLabelProps={{ shrink: true }} fullWidth multiline />
        )}

        <Box component="span" sx={styles.btn}>
          <ButtonWithLoading
            variant="contained"
            color="primary"
            loading={isPlaceholdersFetching || isSavePromptTemplateLoading}
            onClick={handleTemplateSave}
            fullWidth
          >
            Save Template
          </ButtonWithLoading>
        </Box>

        {type.promptType !== 'system' && (
          <>
            <AIModelsSelect
              value={selectedModel}
              onChange={handleSelectedModelChange}
              sx={styles.modelSelect}
              label="AI Model"
              process="ai_optimization"
            />

            <ButtonWithLoading
              variant="contained"
              color="primary"
              loading={isSaveModelLoading || isSelectedModelDataFetching}
              onClick={handleModelSave}
              fullWidth
            >
              Save AI Model
            </ButtonWithLoading>
          </>
        )}
      </Box>

      <ActionsPopover
        anchor={placeholdersAnchorEl}
        open={Boolean(placeholdersAnchorEl)}
        onClose={handlePlaceholdersClose}
        options={placeholdersOptions}
        onClick={value => {
          navigator.clipboard.writeText(`{${value}}`);
          toast.success('Placeholder copied!');
        }}
      />
    </>
  );
}
