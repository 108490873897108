import { Chip, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { Styles } from 'common/types/styles';
import { ChangeEvent, KeyboardEvent, ReactElement, useEffect, useRef, useState } from 'react';

const getStyles = (round: boolean): Styles => ({
  input: {
    borderRadius: round ? '140px' : undefined,
    display: 'flex',
    alignItems: 'center',
    '& input': {
      minWidth: '30%',
      width: 'auto',
    },
  },
  tagsContainer: {
    display: 'flex',
    overflowX: 'auto',
    maxWidth: '70%',
    minWidth: 'auto',
    marginTop: 1,
    height: '100%',
    mt: 0.5,
    mb: 0.5,
    gap: 0.5,
    '::-webkit-scrollbar': { display: 'none' },
  },
});

interface Props {
  value: string[];
  onChange: (value: string[]) => void;
  name?: string;
  placeholder?: string;
  label?: string;
  variant?: TextFieldProps['variant'];
  sx?: SystemStyleObject;
  inputSx?: SystemStyleObject;
  round?: boolean;
  error?: string;
}

export function StyledTagsInput({
  value,
  onChange,
  name,
  placeholder,
  label,
  sx,
  inputSx,
  variant,
  error,
  round = false,
}: Props): ReactElement {
  const [inputValue, setInputValue] = useState<string>('');

  const tagsRef = useRef<HTMLDivElement>();

  const styles = getStyles(round);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleAddTag = () => {
    if (!inputValue) return;
    onChange([...value, inputValue]);
    setInputValue('');
  };

  const handleDeleteTag = (tag: string) => {
    onChange(value.filter(t => t !== tag));
  };

  const handleInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (['Enter'].includes(e.key)) {
      handleAddTag();
    }
  };

  useEffect(() => {
    tagsRef.current?.scrollTo({ left: tagsRef.current?.clientWidth });
  }, [value]);

  return (
    <TextField
      label={label}
      value={inputValue}
      onChange={handleInputChange}
      onKeyDown={handleInputKeyPress}
      onBlur={handleAddTag}
      name={name}
      placeholder={placeholder}
      size="small"
      sx={sx}
      variant={variant}
      error={Boolean(error)}
      helperText={error}
      InputProps={{
        sx: [styles.input, inputSx],
        startAdornment: (
          <InputAdornment sx={styles.tagsContainer} position="start" ref={tagsRef}>
            {value.map(tag => (
              <Chip key={tag} label={tag} onDelete={() => handleDeleteTag(tag)} />
            ))}
          </InputAdornment>
        ),
      }}
      fullWidth
    />
  );
}
