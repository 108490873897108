import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

interface WindowSize {
  width: number;
  height: number;
}

const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const debouncedChange = useDebouncedCallback(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, 150);

  useEffect(() => {
    const handleResize = () => {
      debouncedChange();
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [debouncedChange]);

  return windowSize;
};

export default useWindowSize;
