import { Box, Button, IconButton } from '@mui/material';
import { Styles } from 'common/types/styles';
import { selectedContentCtx } from 'common/ui/shared/selected-content-ctx';
import { userService } from 'common/services/user.service';
import EditIcon from '@mui/icons-material/Edit';
import { RetailerType } from 'common/constants/entities';
import LaunchIcon from '@mui/icons-material/Launch';
import { MouseEvent, ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import { PdpAIModal } from 'common/ui/shared/pdp-ai-modal';
import { ConditionalInstructionsModal } from 'common/ui/shared/conditional-instructions-modal';
// import { getPDPValues } from 'common/services/attributes.service';
import KeyIcon from '@mui/icons-material/Key';
import { getCategoryIDKeyFromProduct } from 'modules/common/content/utils/mappings';
import { useSelectedProductQuery } from 'modules/common/product-details-page/hooks/queries';
import { TargetPDPEnhancePopup } from '../target-pdp-enhance-popup';
import { KeywordsSettingsModal } from '../keywords-settings-modal';

const styles: Styles = {
  rightSideHead: { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 },
};

export function ProductRightSideHead(): ReactElement {
  const nav = useNavigate();

  const { data: productData } = useSelectedProductQuery();

  const [kwsModalOpen, setKWsModalOpen] = useState<boolean>(false);
  const [isAIModalOpen, setIsAIModalOpen] = useState<boolean>(false);
  const [enhancePDPAnchor, setEnhancePDPAnchor] = useState<HTMLButtonElement>(null);
  const [instructionsModalOpen, setInstructionsModalOpen] = useState<boolean>(false);

  // const pdp = getPDPValues(productData);

  const handleProductEdit = (pid: string) => {
    switch (userService.getRetailer()) {
      case RetailerType.Walmart:
      case RetailerType.Flywheel:
        nav(`/content/${pid}`);
        break;
      case RetailerType.Instacart:
      case RetailerType.SyndigoAmazon:
      case RetailerType.SyndigoKroger:
      case RetailerType.SyndigoWalmart:
      case RetailerType.SyndigoTarget:
      case RetailerType.Autozone:
      case RetailerType.Target:
      case RetailerType.Amazon:
      case RetailerType.Costco:
      case RetailerType.Suplery:
        nav(`/product/${pid}`);
        break;
      default:
        break;
    }
  };

  const getShopName = () => {
    switch (userService.getRetailer()) {
      case RetailerType.Walmart:
        return 'Walmart';
      case RetailerType.Target:
        return 'Target';
      case RetailerType.Amazon:
      case RetailerType.Costco:
      case RetailerType.Suplery:
        return '';
      default:
        return '';
    }
  };

  const getProductLink = (pid: string) => {
    switch (userService.getRetailer()) {
      case RetailerType.Walmart:
        return `https://www.walmart.com/ip/${pid}`;
      case RetailerType.Target:
        return `https://www.target.com/p/A-${pid}`;
      case RetailerType.Costco:
        return `https://www.costco.com/abc.product.${pid}.html`;
      case RetailerType.Amazon:
        return `https://www.amazon.com/dp/${productData?.product_id}`;
      default:
        return '';
    }
  };

  const handleAIModalOpen = () => {
    setIsAIModalOpen(true);
  };

  const handleAIModalClose = () => {
    setIsAIModalOpen(false);
  };

  const handleInstructionsModalOpen = () => {
    setInstructionsModalOpen(true);
  };

  const handleInstructionsModalClose = () => {
    setInstructionsModalOpen(false);
  };

  const handleEnhancePDPOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setEnhancePDPAnchor(e.currentTarget);
  };

  const handleEnhancePDPClose = () => {
    setEnhancePDPAnchor(null);
  };

  const handleKWsModalOpen = () => {
    setKWsModalOpen(true);
  };

  const handleKWsModalClose = () => {
    setKWsModalOpen(false);
  };

  return (
    <Box sx={styles.rightSideHead}>
      <selectedContentCtx.Consumer>
        {({ selectedContent }) => (
          <>
            {!userService.ensureRetailer(RetailerType.Instacart) && (
              <>
                {!(
                  userService.ensureRetailer(RetailerType.Walmart) || userService.ensureRetailer(RetailerType.Flywheel)
                ) && (
                  <LightTooltip
                    title={
                      // enhanceDisabled ? 'Please Run Optimization' :
                      'Enhance PDP'
                    }
                  >
                    <span>
                      <IconButton
                        size="small"
                        onClick={handleEnhancePDPOpen}
                        // disabled={enhanceDisabled}
                      >
                        <AutoFixHighIcon fontSize="small" color="action" />
                      </IconButton>
                    </span>
                  </LightTooltip>
                )}
                <LightTooltip title="Optimized AI Template">
                  <IconButton size="small" onClick={handleAIModalOpen}>
                    <AutoAwesomeIcon fontSize="small" color="action" />
                  </IconButton>
                </LightTooltip>
                {userService.ensureRetailers([RetailerType.Target, RetailerType.Amazon]) && (
                  <LightTooltip title="Keywords Settings">
                    <IconButton size="small" onClick={handleKWsModalOpen}>
                      <KeyIcon fontSize="small" color="action" />
                    </IconButton>
                  </LightTooltip>
                )}
                <LightTooltip title="Conditional Instructions">
                  <IconButton onClick={handleInstructionsModalOpen}>
                    <IntegrationInstructionsIcon fontSize="small" color="action" />
                  </IconButton>
                </LightTooltip>
                {((!userService.ensureRetailer(RetailerType.Flywheel) &&
                  !userService.ensureRetailer(RetailerType.Amazon)) ||
                  (userService.ensureRetailer(RetailerType.Amazon) && productData?.product_id)) && (
                  <LightTooltip title={`${getShopName()} Link`}>
                    <IconButton onClick={() => window.open(getProductLink(selectedContent))}>
                      <LaunchIcon fontSize="small" />
                    </IconButton>
                  </LightTooltip>
                )}
                <PdpAIModal pid={selectedContent} open={isAIModalOpen} onClose={handleAIModalClose} />
                <KeywordsSettingsModal open={kwsModalOpen} onClose={handleKWsModalClose} pid={selectedContent} />
                <ConditionalInstructionsModal
                  open={instructionsModalOpen}
                  onClose={handleInstructionsModalClose}
                  pid={selectedContent}
                  categoryID={(productData ?? {})[getCategoryIDKeyFromProduct()]}
                />
                {!(
                  userService.ensureRetailer(RetailerType.Walmart) || userService.ensureRetailer(RetailerType.Flywheel)
                ) && (
                  <TargetPDPEnhancePopup
                    anchorEl={enhancePDPAnchor}
                    pid={selectedContent}
                    onClose={handleEnhancePDPClose}
                  />
                )}
              </>
            )}
            <Button variant="contained" color="primary" onClick={() => handleProductEdit(selectedContent)}>
              <EditIcon fontSize="small" />
              Edit Product
            </Button>
          </>
        )}
      </selectedContentCtx.Consumer>
    </Box>
  );
}
