import { FormAttributeEntry } from 'common/types/common';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { Fragment, MouseEvent, ReactElement, useState } from 'react';
import { TextComparator } from 'common/ui/containers/text-comparator';
import { IssuesButton, IssuesPopup } from 'common/ui/shared/issues-popup';
import { userService } from 'common/services/user.service';
import { sortBy } from 'lodash';
import { RetailerType } from 'common/constants/entities';
import { Styles } from 'common/types/styles';
import { StyledAccordion } from '../styled-accordion';
import { DetailsTabValue } from '../../constants';
import { useSelectedCategoryQuery, useSelectedProductQuery } from '../../hooks/queries';

const styles: Styles = {
  specKey: { fontSize: 14, fontWeight: 600, wordBreak: 'break-all' },
  specValue: { fontSize: 14 },
  specKeyContainer: { display: 'flex', alignItems: 'center', gap: 1 },
};

interface Props {
  specs: Array<FormAttributeEntry>;
  title: string;
  source: DetailsTabValue;
  defaultExpanded?: boolean;
}

export function SpecsAccordion({ specs, title, source, defaultExpanded }: Props): ReactElement {
  const [issuesAnchor, setIssuesAnchor] = useState<HTMLButtonElement>(null);
  const [issuesKey, setIssuesKey] = useState<string>(null);

  const { data: productData, isFetching: isProductFetching } = useSelectedProductQuery();
  const { isFetching: isCategoryFetching } = useSelectedCategoryQuery();

  const parseArraySpec = (v: string | string[]) => {
    if (!v) return '';
    if (!Array.isArray(v)) return sortBy(v.split(';')).join(';');
    return sortBy(v).join(';');
  };

  const getSpecValue = (spec: FormAttributeEntry) => {
    switch (source) {
      case DetailsTabValue.Final:
        return (spec.rules.multi_select === 'Yes' ? parseArraySpec(spec.formValue) : String(spec.formValue)) || '-';
      case DetailsTabValue.Original:
        return (spec.rules.multi_select === 'Yes' ? parseArraySpec(spec.initialValue) : spec.initialValue) || '-';
      default:
        return '-';
    }
  };

  return (
    <StyledAccordion title={title} defaultExpanded={defaultExpanded}>
      <Grid container spacing={2}>
        {!isCategoryFetching && !isProductFetching
          ? specs.map(spec => (
              <Fragment key={spec.key}>
                <Grid xs={3} item>
                  <Box sx={styles.specKeyContainer}>
                    {(userService.ensureRetailer(RetailerType.Walmart) ||
                      userService.ensureRetailer(RetailerType.Flywheel)) && (
                      <IssuesButton
                        buttonProps={{
                          onClick: (e: MouseEvent<HTMLButtonElement>) => {
                            setIssuesAnchor(e.currentTarget);
                            setIssuesKey(spec.key);
                          },
                        }}
                        product={productData}
                        attributeKeys={[spec.key]}
                      />
                    )}
                    <Typography sx={styles.specKey}>{spec.key}</Typography>
                  </Box>
                </Grid>
                <Grid xs={9} item>
                  {source === DetailsTabValue.Compare ? (
                    <TextComparator
                      previousText={
                        (spec.rules.multi_select === 'Yes' ? parseArraySpec(spec.initialValue) : spec.initialValue) ||
                        ''
                      }
                      currentText={
                        (spec.rules.multi_select === 'Yes' ? parseArraySpec(spec.formValue) : String(spec.formValue)) ||
                        ''
                      }
                      sx={styles.specValue}
                    />
                  ) : (
                    <Typography sx={styles.specValue}>{getSpecValue(spec)}</Typography>
                  )}
                </Grid>
              </Fragment>
            ))
          : [1, 2, 3].map(i => (
              <Fragment key={i}>
                <Grid xs={3} item>
                  <Skeleton variant="text" />
                </Grid>
                <Grid xs={9} item>
                  <Skeleton variant="text" />
                </Grid>
              </Fragment>
            ))}
      </Grid>
      {(userService.ensureRetailer(RetailerType.Walmart) || userService.ensureRetailer(RetailerType.Flywheel)) && (
        <IssuesPopup
          attributeKeys={[issuesKey]}
          product={productData}
          anchorElement={issuesAnchor}
          extendedColumns={['Status']}
          onClose={() => {
            setIssuesAnchor(null);
            setIssuesKey(null);
          }}
        />
      )}
    </StyledAccordion>
  );
}
