import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { StyledTagsInput } from './styled-tags-input';

interface Props {
  name: string;
}

export function TagsField({ name }: Props): ReactElement {
  const { watch, setValue } = useFormContext();
  const value = watch(name);

  const handleChange = (value: string[]) => {
    setValue(name, value);
  };

  return <StyledTagsInput value={value} onChange={handleChange} />;
}
