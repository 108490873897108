import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { ReactElement, useState } from 'react';
import { ActionNotification, ActionNotificationStatus } from 'common/types/common';
import { format } from 'date-fns';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import { useExportStatusMutation } from 'common/hooks/api/mutations/use-export-mutation';
import DownloadIcon from '@mui/icons-material/Download';
import { ButtonWithLoading } from 'common/ui/containers/button-with-loading';
import { Link } from 'react-router-dom';
import { RetailerType } from 'common/constants/entities';

const styles: Styles = {
  notification: { display: 'flex', alignItems: 'center', gap: 2, p: 2 },
  expandBtn: { fontSize: 11, height: 20, color: theme.palette.grey[600] },
  date: { ml: 'auto', fontSize: 14 },
  expandableContainer: { display: 'flex', flexDirection: 'column', p: 2, gap: 1 },
  infoTitle: { fontSize: 14 },
  infoContainer: { backgroundColor: theme.palette.grey[100], borderRadius: '5px', p: 1, fontSize: 14 },
  link: {
    fontSize: 12,
    width: 500,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
};

function transformActionType(type: string): string {
  return type
    .split('-')
    .map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word.toLowerCase()))
    .join(' ');
}

interface Props {
  notification: ActionNotification;
}

export function Notification({ notification }: Props): ReactElement {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [exportLinksLoaded, setExportLinksLoaded] = useState<boolean>(false);
  const [exportLinks, setExportLinks] = useState<string[]>([]);

  const { mutate: getExportStatus, isLoading: isExportStatusLoading } = useExportStatusMutation();

  const toggleExpand = () => {
    setExpanded(expanded => !expanded);
  };

  const getNotificationStatusIcon = (n: ActionNotification) => {
    switch (n.status) {
      case ActionNotificationStatus.FAILED:
        return <FeedbackIcon color="error" />;
      case ActionNotificationStatus.SUCCESS:
        return <CheckCircleIcon color="success" />;
      default:
        return <CircularProgress size={20} />;
    }
  };

  const downloadExport = () => {
    if (!notification.meta.exportID) return;
    getExportStatus(String(notification.meta.exportID), {
      onSuccess: ({ links }) => {
        setExportLinksLoaded(true);
        setExportLinks(links);
      },
    });
  };

  const renderNotificationResult = () => {
    if (notification.meta?.exportID && !exportLinksLoaded && notification.status !== ActionNotificationStatus.FAILED) {
      return (
        <ButtonWithLoading onClick={downloadExport} variant="outlined" loading={isExportStatusLoading}>
          <DownloadIcon />
          Load Export Result
        </ButtonWithLoading>
      );
    }

    if (exportLinks.length) {
      return (
        <Box sx={styles.infoContainer}>
          {exportLinks.map(link => (
            <Typography component="a" href={link} sx={styles.link} key={link} target="_blank">
              {link}
            </Typography>
          ))}
        </Box>
      );
    }

    if (!notification.meta?.exportID) {
      return (
        <Box sx={styles.infoContainer}>
          {notification.meta?.result
            ? Object.entries(notification.meta.result).map(([key, value]) => (
                <Typography key={key} fontSize="inherit">
                  {key}: {JSON.stringify(value)}
                </Typography>
              ))
            : '-'}
        </Box>
      );
    }

    return <Box sx={styles.infoContainer}>-</Box>;
  };

  const renderPayloadEntry = (key: string, value: unknown) => {
    if (key === 'productID') {
      return (
        <Typography key={key} fontSize="inherit">
          Product:{' '}
          <Link
            to={
              notification.vendor_name.toLowerCase() === RetailerType.Walmart.toLowerCase()
                ? `/content/${value}`
                : `/product/${value}`
            }
          >
            {String(value)}
          </Link>
        </Typography>
      );
    }

    return (
      <Typography key={key} fontSize="inherit">
        {key}: {JSON.stringify(value)}
      </Typography>
    );
  };

  return (
    <>
      <Box sx={styles.notification}>
        <LightTooltip title={notification.message || notification.status}>
          {getNotificationStatusIcon(notification)}
        </LightTooltip>
        <Typography>
          {transformActionType(notification.type)} [{notification.vendor_name}]
        </Typography>
        <Typography sx={styles.date}>{format(new Date(notification.updated_at), 'yyyy-MM-dd hh:mm a')}</Typography>
      </Box>
      {expanded && (
        <Box sx={styles.expandableContainer}>
          <Typography sx={styles.infoTitle}>Payload</Typography>
          <Box sx={styles.infoContainer}>
            {notification.meta?.payload
              ? Object.entries(notification.meta.payload).map(([key, value]) => renderPayloadEntry(key, value))
              : '-'}
          </Box>

          {notification.status !== ActionNotificationStatus.IN_PROGRESS && (
            <>
              <Typography sx={styles.infoTitle}>Result</Typography>
              {renderNotificationResult()}
            </>
          )}
        </Box>
      )}
      <Button size="small" color="inherit" sx={styles.expandBtn} onClick={toggleExpand} fullWidth>
        {expanded ? 'Hide Info' : 'Expand Info'}
      </Button>
    </>
  );
}
