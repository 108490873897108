import { forwardRef, ReactElement } from 'react';
import { CircularProgress, IconButton, IconButtonProps } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { isBefore } from 'date-fns';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import { useNotificationsContext } from './notifications-context';

export const NotificationsButton = forwardRef<HTMLButtonElement, IconButtonProps>(function NotificationsButton(
  props: IconButtonProps,
  ref
): ReactElement {
  const { globalLoading, notifications, lastOpened } = useNotificationsContext();

  const renderNotificationIcon = () => {
    if (!lastOpened || notifications.some(n => isBefore(Number(lastOpened), new Date(n.updated_at)))) {
      return <NotificationsActiveIcon color="primary" />;
    }
    return <NotificationsIcon />;
  };

  return (
    <LightTooltip title="Notifications">
      <IconButton {...props} ref={ref}>
        {globalLoading ? <CircularProgress size={20} /> : renderNotificationIcon()}
      </IconButton>
    </LightTooltip>
  );
});
