import { Modal } from 'common/ui/containers/modal';
import { PDPKeywords } from 'common/ui/shared/pdp-ai-modal/pdp-keywords';
import { ReactElement } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  pid: string;
}

export function KeywordsSettingsModal({ open, onClose, pid }: Props): ReactElement {
  return (
    <Modal open={open} onClose={onClose} title="Keywords Settings" maxWidth="xl">
      <PDPKeywords pid={pid} />
    </Modal>
  );
}
