import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { RetailerType } from 'common/constants/entities';
import { attributesApi } from 'common/services/api/attributes/attributes-api.service';
import {
  FetchProductCountParams,
  GetAICategoryProductsParams,
  GetAIModelsParams,
  GetAIQueueProductsV4Params,
  GetAIQueueStatsV4Params,
  GetAttributeAITemplatesParams,
  GetSpecialInstructionsParams,
  GetSyncHistoryFilters,
  PreviewAIPromptParams,
  SearchAttributesParams,
} from 'common/services/api/attributes/attributes-api.types';
import { userService } from 'common/services/user.service';
import { AttributeHistoryEntry, IPagination } from 'common/types/common';

export enum AttributesQueryKey {
  AttributesSuggestions = 'AttributesSuggestions',
  Mapping = 'Mapping',
  AIPreview = 'AIPreview',
  AttributesGroups = 'AttributesGroups',
  AIQueueStats = 'AIQueueStats',
  AIQueueStatsV4 = 'AIQueueStatsV4',
  AIQueueProductsV4 = 'AIQueueProductsV4',
  AIQueueStatus = 'AIQueueStatus',
  AICategoryProducts = 'AICategoryProducts',
  AIModels = 'AIModels',
  AITemplates = 'AITemplates',
  ConditionalInstructions = 'ConditionalInstructions',
  ConditionalInstructionsFields = 'ConditionalInstructionsFields',
  SpecialInstructions = 'SpecialInstructions',
  SyncHistory = 'SyncHistory',
  Keywords = 'Keywords',
  ProductCount = 'ProductCount',
  SearchAttributes = 'SearchAttributes',
  KeywordsSuggestions = 'KeywordsSuggestions',
  GlobalAISettings = 'GlobalAISettings',
  ProductSpecialInstructions = 'ProductSpecialInstructions',
  ConditionalFieldValues = 'ConditionalFieldValues',
}

export function useAttributeSuggestionsQuery(categoryID: number | string, attribute: string, enabled: boolean) {
  const query = useQuery<Record<string, string[]>>({
    queryKey: [AttributesQueryKey.AttributesSuggestions, categoryID, attribute],
    enabled,
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      const { data } = await attributesApi.getSuggestions(categoryID, attribute);
      return data.suggestions;
    },
  });
  return query;
}

export function useMappingQuery(enabled?: boolean) {
  const query = useQuery<Record<string, string[]>>({
    queryKey: [AttributesQueryKey.Mapping],
    queryFn: async () => {
      const { data } = await attributesApi.getMappings();
      return data.mapping;
    },
    enabled,
  });

  return query;
}

export function useAttributeAIPreviewQuery(params: PreviewAIPromptParams, enabled?: boolean) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.AIPreview, params],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await attributesApi.previewAI(retailer, params);
      return data.aiInput;
    },
    enabled,
  });

  return query;
}

export function useAttributesGroupsQuery(categoryID: number | string) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.AttributesGroups, categoryID],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await attributesApi.getAttributesGroups(categoryID, retailer);
      return data.attributeGroups;
    },
    enabled: Boolean(categoryID),
  });

  return query;
}

export function useAIQueueStatsQuery() {
  const query = useQuery({
    queryKey: [AttributesQueryKey.AIQueueStats],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await attributesApi.getAIQueueStats(retailer);
      return data.stats;
    },
  });

  return query;
}

export function useAIQueueStatsQueryV4(params: Omit<GetAIQueueStatsV4Params, 'sub_vendor_id'>) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.AIQueueStatsV4, params],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const subVendorID = userService.getSubRetailer();
      const { data } = await attributesApi.getAIQueueStatsV4(retailer, { ...params, sub_vendor_id: subVendorID });
      return data;
    },
  });

  return query;
}

export function useAIQueueProductsQueryV4(params: GetAIQueueProductsV4Params) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.AIQueueProductsV4, params],
    keepPreviousData: true,
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await attributesApi.getAIQueueProductsV4(retailer, params);
      return data;
    },
  });

  return query;
}

export function useAIQueueProductsQueryStatus() {
  const query = useQuery({
    queryKey: [AttributesQueryKey.AIQueueStatus],
    keepPreviousData: true,
    refetchInterval: 1000 * 10,
    queryFn: async () => {
      const { data } = await attributesApi.getAIQueueStatus();
      return data;
    },
  });

  return query;
}

export function useAICategoryProductsQuery(params: GetAICategoryProductsParams, enabled?: boolean) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.AICategoryProducts, params],
    staleTime: 1000 * 10,
    enabled,
    queryFn: async () => {
      const { data } = await attributesApi.getAICategoryProducts(params);
      return data;
    },
  });

  return query;
}

export function useAIModelsQuery(params?: GetAIModelsParams) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.AIModels, params],
    queryFn: async () => {
      const { data } = await attributesApi.getAIModels(params);
      return data.models;
    },
  });

  return query;
}

export function useAttributeAITemplates(params: GetAttributeAITemplatesParams, enabled?: boolean) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.AITemplates],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await attributesApi.getAttributeAITemplates(retailer, params);
      return data;
    },
    enabled,
  });

  return query;
}

export function useConditionalInstructionsQuery(params: IPagination, enabled = true) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.ConditionalInstructions, params],
    enabled,
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await attributesApi.getConditionalInstructions(retailer, params);
      return data;
    },
  });

  return query;
}

export function useSpecialInstructionsQuery(params: GetSpecialInstructionsParams) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.SpecialInstructions, params],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = userService.ensureRetailer(RetailerType.Walmart)
        ? await attributesApi.getWalmartSpecialInstructions(retailer, params)
        : await attributesApi.getSpecialInstructions(retailer, params);
      return data;
    },
  });

  return query;
}

export function useConditionalInstructionsFieldsQuery() {
  const query = useQuery({
    queryKey: [AttributesQueryKey.ConditionalInstructionsFields],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await attributesApi.getConditionalInstructionsFields(retailer);
      return data;
    },
  });

  return query;
}

export function useSyncAttributesHistoryQuery(filters?: GetSyncHistoryFilters) {
  const query = useInfiniteQuery({
    queryKey: [AttributesQueryKey.SyncHistory, filters],
    getNextPageParam: (lastPage: AttributeHistoryEntry[]) => {
      return lastPage.length ? lastPage[lastPage.length - 1].date : undefined;
    },
    queryFn: async ctx => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await attributesApi.getSyncHistory({ ...filters, cursorNext: ctx.pageParam }, retailer);
      return data;
    },
  });

  return query;
}

export function useKeywordsQuery(pid: string, retailerOverride?: RetailerType) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.Keywords, pid, retailerOverride],
    queryFn: async () => {
      const retailer = retailerOverride ?? (userService.getAPIRetailer() as RetailerType);
      const { data } = await attributesApi.getKeywords(retailer, pid);
      return data;
    },
  });

  return query;
}

export function useKeywordsSuggestionsQuery(search: string, retailerOverride?: RetailerType) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.KeywordsSuggestions, search, retailerOverride],
    queryFn: async () => {
      const retailer = retailerOverride ?? (userService.getAPIRetailer() as RetailerType);
      const { data } = await attributesApi.searchKeywordsSuggestions(retailer, search);
      return data;
    },
    enabled: Boolean(search),
  });

  return query;
}

export function useProductCountQuery(params: FetchProductCountParams) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.ProductCount, params],
    enabled:
      Boolean(params.wsScopeId && userService.ensureRetailer(RetailerType.Walmart)) ||
      !userService.ensureRetailer(RetailerType.Walmart),
    queryFn: async () => {
      const retailer = userService.getAPIRetailer() as RetailerType;
      const { data } = await attributesApi.fetchProductCount(retailer, params);
      return data?.count;
    },
  });

  return query;
}

export function useAttributesSearch<T extends boolean>(params: SearchAttributesParams<T>) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.SearchAttributes, params],
    queryFn: async () => {
      const retailer = userService.getAPIRetailer();
      const { data } = await (retailer === RetailerType.Walmart
        ? attributesApi.searchAttributes(params)
        : attributesApi.searchVendorsAttributes(retailer, { ...params, subVendorID: userService.getSubRetailer() }));
      return data;
    },
  });

  return query;
}

export function useGlobalAISettingsQuery() {
  const query = useQuery({
    queryKey: [AttributesQueryKey.GlobalAISettings],
    queryFn: async () => {
      const { data } = await attributesApi.getGlobalAISettings();
      return data;
    },
  });

  return query;
}

export function useProductSpecialInstructionsQuery(pid: string, enabled = true) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.ProductSpecialInstructions, pid],
    enabled,
    queryFn: async () => {
      const { data } = await attributesApi.getProductSpecialInstructions(userService.getAPIRetailer(), pid);
      return data;
    },
  });

  return query;
}

export function useConditionalFieldValuesQuery(pid: string) {
  const query = useQuery({
    queryKey: [AttributesQueryKey.ConditionalFieldValues, pid],
    queryFn: async () => {
      const { data } = await attributesApi.getConditionalFieldValues(userService.getAPIRetailer(), pid);
      return data;
    },
  });

  return query;
}
