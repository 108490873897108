import { Box, Button, IconButton, TextField } from '@mui/material';
import { Styles } from 'common/types/styles';
import { FieldLabelWrapper } from 'common/ui/inputs/field-label-wrapper';
import { Fragment, ReactElement, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { SelectField, SelectFieldOption } from 'common/ui/inputs/select-field';
import { useSelectScopes } from 'common/ui/inputs/scopes/scope-select/use-select-scopes';
import { userService } from 'common/services/user.service';
import { useScopeContext } from 'common/ui/inputs/scopes/scope-ctx';
import { useCategoryQuery } from 'common/hooks/api/queries/use-category-query';
import {
  useConditionalFieldValuesQuery,
  useConditionalInstructionsFieldsQuery,
} from 'common/hooks/api/queries/use-attributes-query';
import { StyledTagsInput } from 'common/ui/inputs/tags-field';
import { BooleanOperator } from '../../boolean-operator';
import { useInstructionContext } from '../instruction-context';
// eslint-disable-next-line import/no-cycle
import { initialConditionEntry } from '../constants';

const operatorOptions: Array<SelectFieldOption> = ['contains', 'in', 'eq', 'ne'].map(o => ({ label: o, value: o }));
const attributeTypeOptions: Array<SelectFieldOption> = ['text', 'measure', 'number'].map(o => ({ label: o, value: o }));

export interface Condition {
  field: string;
  operator: string;
  value: string | string[];
}

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' },
  condition: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: 1.5,
    backgroundColor: '#f5f5f5',
    p: 1.5,
    width: '100%',
    boxSizing: 'border-box',
  },
  input: { borderRadius: 0, backgroundColor: '#fff' },
  commonInputContainer: { maxWidth: 200, width: '100%' },
  valueInputContainer: { flexGrow: 1 },
  buttons: { display: 'flex', alignItems: 'center', gap: 1.5 },
  operatorButton: { width: 70, textTransform: 'uppercase', height: 48, backgroundColor: '#fff' },
};

interface Props {
  expressionIndex: number;
  handleOr: () => void;
  handleRemoveExpression: (index: number) => void;
}

export function ExpressionBlock({ expressionIndex, handleOr, handleRemoveExpression }: Props): ReactElement {
  const conditionsKey = `expressions.${expressionIndex}.conditions` as const;

  const { control, getValues, trigger, getFieldState } = useFormContext<AIModule.Conditions.InstructionForm>();
  const { fields, insert, remove, update } = useFieldArray({
    name: conditionsKey,
    control,
  });
  const { pid, categoryID } = useInstructionContext();
  const { wsScope } = useScopeContext();
  const scopes = useSelectScopes({ retailer: userService.getAPIRetailer(), ws: wsScope?.ws_scope });

  const scopesOptons: SelectFieldOption[] = useMemo(() => {
    if (!scopes) return [];
    return scopes.map(scope => ({ label: scope.name, value: scope.name })) ?? [];
  }, [scopes]);

  const { data: categoryData } = useCategoryQuery({ id: categoryID, enabled: true });
  const { data: fieldsData } = useConditionalInstructionsFieldsQuery();
  const { data: fieldsValuesData } = useConditionalFieldValuesQuery(pid);

  const fieldsOptions: Array<SelectFieldOption> = useMemo(() => {
    if (!fieldsData) return [];
    return fieldsData.fields.map(f => ({ label: f, value: f }));
  }, [fieldsData]);

  const attributesOptions: Array<SelectFieldOption> = useMemo(() => {
    if (!categoryData) return [];
    return Object.keys(categoryData).map(cat => ({ label: cat, value: cat }));
  }, [categoryData]);

  const handleAnd = (sourceIndex: number) => {
    insert(sourceIndex + 1, initialConditionEntry);
  };

  const handleRemove = (conditionIndex: number) => {
    const currentExpression = getValues().expressions[expressionIndex];
    if (currentExpression.conditions.length === 1) return handleRemoveExpression(expressionIndex);
    return remove(conditionIndex);
  };

  const getSuggestion = (conditionIndex: number) => {
    const currentCondition = getValues().expressions[expressionIndex].conditions[conditionIndex];
    let value = '';

    switch (currentCondition.field) {
      case 'Brand':
        value = fieldsValuesData?.values.brand;
        break;
      case 'Product Title':
        value = fieldsValuesData?.values.productTitle;
        break;
      case 'Product Description':
        value = fieldsValuesData?.values.productDescription;
        break;
      case 'Product Features':
        value = fieldsValuesData?.values.productFeatures;
        break;
      case 'Product Type':
        value = fieldsValuesData?.values.productType;
        break;
      default:
        break;
    }

    return currentCondition.operator === 'in' ? [value].filter(Boolean) : value;
  };

  const handlePrefill = (conditionIndex: number) => {
    const currentCondition = getValues().expressions[expressionIndex].conditions[conditionIndex];
    const suggestion = getSuggestion(conditionIndex);

    update(conditionIndex, { ...currentCondition, value: suggestion });
  };

  return (
    <Box sx={styles.container}>
      {fields.map((c, i) => (
        <Fragment key={c.id}>
          <Box sx={styles.condition}>
            <FieldLabelWrapper label="Field" sx={styles.commonInputContainer}>
              <Controller
                control={control}
                name={`expressions.${expressionIndex}.conditions.${i}.field`}
                render={({ field, fieldState }) => (
                  <SelectField
                    value={field.value}
                    onChange={field.onChange}
                    variant="filled"
                    selectSx={styles.input}
                    options={fieldsOptions}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </FieldLabelWrapper>
            <FieldLabelWrapper label="Operator" sx={styles.commonInputContainer}>
              <Controller
                control={control}
                name={`expressions.${expressionIndex}.conditions.${i}.operator`}
                render={({ field, fieldState }) => (
                  <SelectField
                    value={field.value}
                    onChange={(value: string) => {
                      const condition = getValues().expressions[expressionIndex].conditions[i];
                      update(i, { ...condition, operator: value, value: value === 'in' ? [] : '' });
                      const valueKey = `${conditionsKey}.${i}.value` as const;
                      const valueState = getFieldState(valueKey);
                      if (valueState.error) trigger(valueKey);
                    }}
                    variant="filled"
                    selectSx={styles.input}
                    options={operatorOptions}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </FieldLabelWrapper>
            <FieldLabelWrapper label="Value" sx={styles.valueInputContainer}>
              <Controller
                control={control}
                name={`expressions.${expressionIndex}.conditions.${i}.field`}
                render={({ field: { value: fieldEntry } }) => (
                  <Controller
                    control={control}
                    name={`expressions.${expressionIndex}.conditions.${i}.value`}
                    render={({ field: valueField, fieldState: valueFieldState }) => {
                      if (fieldEntry === 'Scopes') {
                        return (
                          <SelectField
                            {...valueField}
                            error={valueFieldState.error?.message}
                            variant="filled"
                            options={scopesOptons}
                            multiple={c.operator === 'in'}
                            selectSx={styles.input}
                          />
                        );
                      }

                      if (fieldEntry.includes('Attribute') && fieldEntry !== 'Attribute Value') {
                        return (
                          <SelectField
                            {...valueField}
                            error={valueFieldState.error?.message}
                            variant="filled"
                            options={fieldEntry === 'Attribute' ? attributesOptions : attributeTypeOptions}
                            multiple={c.operator === 'in'}
                            selectSx={styles.input}
                          />
                        );
                      }

                      if (c.operator === 'in' && Array.isArray(valueField.value)) {
                        return (
                          <StyledTagsInput
                            value={valueField.value}
                            onChange={valueField.onChange}
                            variant="filled"
                            inputSx={styles.input}
                            error={valueFieldState.error?.message}
                            round={false}
                            placeholder={String(getSuggestion(i))}
                          />
                        );
                      }

                      return (
                        <TextField
                          {...valueField}
                          error={Boolean(valueFieldState.error)}
                          helperText={valueFieldState.error?.message}
                          size="small"
                          variant="filled"
                          InputProps={{ sx: styles.input }}
                          placeholder={String(getSuggestion(i))}
                        />
                      );
                    }}
                  />
                )}
              />
            </FieldLabelWrapper>
            <Box sx={styles.buttons}>
              <Controller
                control={control}
                name={`expressions.${expressionIndex}.conditions.${i}.field`}
                render={({ field }) =>
                  field.value.includes('Attribute') || !field.value ? null : (
                    <Button sx={styles.operatorButton} onClick={() => handlePrefill(i)}>
                      Fill
                    </Button>
                  )
                }
              />
              <Button sx={styles.operatorButton} onClick={() => handleAnd(i)}>
                And
              </Button>
              {fields.length - 1 === i && (
                <Button sx={styles.operatorButton} onClick={handleOr}>
                  Or
                </Button>
              )}
              <IconButton size="small" onClick={() => handleRemove(i)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          {i !== fields.length - 1 && <BooleanOperator value="and" withConnections />}
        </Fragment>
      ))}
    </Box>
  );
}
