import { Box, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import DeleteIcon from '@mui/icons-material/Delete';
import RepeatIcon from '@mui/icons-material/Repeat';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CachedIcon from '@mui/icons-material/Cached';
import { ReactElement } from 'react';
import { Styles } from 'common/types/styles';

const styles: Styles = {
  icon: { cursor: 'pointer' },
  container: { display: 'flex', alignItems: 'center', gap: '10px' },
  button: {
    height: 30,
    width: 30,
    minWidth: 30,
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
};

enum ActionType {
  preview = 'preview',
  page = 'page',
  edit = 'edit',
  delete = 'delete',
  repeat = 'repeat',
  cache = 'cache',
}

interface Props {
  show: Array<keyof typeof ActionType>;
  onPage?: () => void;
  onPreview?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onRepeat?: () => void;
  onCache?: () => void;
  config?: Partial<Record<keyof typeof ActionType, string>>;
}

export function TableRowActions({
  show,
  onPreview,
  onEdit,
  onDelete,
  onRepeat,
  onPage,
  onCache,
  config,
}: Props): ReactElement {
  return (
    <Box sx={styles.container}>
      {show.includes(ActionType.repeat) && (
        <Tooltip title={config?.repeat ?? 'Repeat'}>
          <Box sx={styles.button} onClick={onRepeat}>
            <RepeatIcon sx={styles.icon} fontSize="small" color="action" />
          </Box>
        </Tooltip>
      )}
      {show.includes(ActionType.preview) && (
        <Tooltip title={config?.preview ?? 'Preview'}>
          <Box sx={styles.button} onClick={onPreview}>
            <PreviewIcon sx={styles.icon} fontSize="small" color="action" />
          </Box>
        </Tooltip>
      )}
      {show.includes(ActionType.page) && (
        <Tooltip title={config?.page ?? 'Page'}>
          <Box sx={styles.button} onClick={onPage}>
            <NewspaperIcon sx={styles.icon} fontSize="small" color="action" />
          </Box>
        </Tooltip>
      )}
      {show.includes(ActionType.edit) && (
        <Tooltip title={config?.edit ?? 'Edit'}>
          <Box sx={styles.button} onClick={onEdit}>
            <EditIcon sx={styles.icon} fontSize="small" color="action" />
          </Box>
        </Tooltip>
      )}
      {show.includes(ActionType.delete) && (
        <Tooltip title={config?.delete ?? 'Delete'}>
          <Box sx={styles.button} onClick={onDelete}>
            <DeleteIcon sx={styles.icon} fontSize="small" color="action" />
          </Box>
        </Tooltip>
      )}
      {show.includes(ActionType.cache) && (
        <Tooltip title={config?.cache ?? 'Cache'}>
          <Box sx={styles.button} onClick={onCache}>
            <CachedIcon sx={styles.icon} fontSize="small" color="action" />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
}
