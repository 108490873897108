import { AxiosResponse } from 'axios';
import { omit } from 'lodash';
import { ApiService } from 'common/services';
import { ExtendedContentEntry, ScopeId, TemplateRule } from 'common/types/common';
import {
  PocRetailers,
  RetailerType,
  selectAllValue,
  SyndigoRetailers,
  WalmartRetailers,
} from 'common/constants/entities';
import { userService } from 'common/services/user.service';
import {
  EnsureProductBody,
  ExportProductsCsvBody,
  ResetValueBody,
  SaveScrapedAttributesData,
  SuggestCategoriesData,
  TargetProductsFilters,
  TargetProductsListResponse,
} from './products-api.types';

function getTags(search: Array<string>) {
  const excludedFlags = ['flag:nenr', 'flag:psma', 'flag:invalid@ipt', 'flag:invalid', 'flag:example'];
  const filteredTags = search.filter(s => s.includes(':'));
  const hasExcludedFlags = filteredTags.some(tag => excludedFlags.includes(tag));
  const t = filteredTags.join(',').trim();

  if (!t.length) return undefined;
  return hasExcludedFlags ? t : t.replace(':', '@');
}

function getTCINs(search: Array<string>) {
  const ids =
    !userService.ensureRetailers(PocRetailers) &&
    !userService.ensureRetailers(SyndigoRetailers) &&
    !userService.ensureRetailer(RetailerType.Target) &&
    !userService.ensureRetailer(RetailerType.Flywheel) &&
    !userService.ensureRetailer(RetailerType.Autozone)
      ? search.filter(s => !s.includes(':') && /\d/.test(s) && Number.isInteger(Number(s)))
      : search.filter(s => !s.includes(':') && /\d/.test(s));

  if (!ids.length) return undefined;
  return userService.ensureRetailer(RetailerType.Flywheel) ? ids?.join(',') : ids;
}

function findName(values: string[]): string {
  const regex = /^\D+$/;

  // eslint-disable-next-line no-restricted-syntax
  for (const str of values) {
    if (regex.test(str)) {
      return str;
    }
  }

  return undefined;
}

function parseProductsFilters(retailer: RetailerType, args?: TargetProductsFilters) {
  if (!args) return undefined;

  const pid = retailer === RetailerType.Flywheel ? 'wpIDs' : 'tcins';

  const pidsList = retailer === RetailerType.Flywheel ? args?.tcins?.join(',') : args?.tcins;

  return {
    limit: args.limit,
    offset: args.offset,
    ...(args.scope_ids?.length && !args.scope_ids?.includes(selectAllValue)
      ? { scope_ids: args.scope_ids.join(',') }
      : {}),
    ...(args.category_id ? { category_id: args.category_id } : {}),
    ...(args.assigned_users?.length ? { assigned_users: args.assigned_users.join(',') } : {}),
    ...(args.approved_by?.length ? { approved_by: args.approved_by.join(',') } : {}),
    ...(args.actor_ids?.length ? { actor_ids: args.actor_ids.join(',') } : {}),
    ...(args.statuses?.length ? { statuses: args.statuses.join(',') } : {}),
    ...(args.content !== undefined ? { content: true } : {}),
    ...(args.extended !== undefined ? { extended: true } : {}),
    ...(args.skip_level !== undefined ? { skip_level: args.skip_level } : {}),
    ...(args.calc_attributes !== undefined ? { calc_attributes: args.calc_attributes } : {}),
    ...(args.tcins !== undefined ? { [pid]: args.tcins.length ? pidsList : '' } : {}),
    ...(args.categoryIDs?.length ? { category_ids: args.categoryIDs } : {}),
    ...(args.sub_vendor_id !== undefined ? { sub_vendor_id: args.sub_vendor_id } : {}),
    ...(args.flagged !== undefined ? { flagged: args.flagged } : {}),
    ...(args.import_id !== undefined ? { import_id: args.import_id } : {}),
    ...(args.search?.length
      ? {
          tags: getTags(args.search),
          [pid]: getTCINs(args.search),
          ...(findName(args.search) ? { search: findName(args.search) } : {}),
        }
      : {}),
  };
}

class ProductsApiService extends ApiService {
  getProducts = (
    retailer: RetailerType,
    args?: TargetProductsFilters
  ): Promise<AxiosResponse<TargetProductsListResponse>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/products`, {
      params: parseProductsFilters(retailer, args),
    });
  };

  getProductById = (retailer: RetailerType, id: string): Promise<AxiosResponse<{ product: ExtendedContentEntry }>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/products/${id}/content`);
  };

  getRetailerAttributes = (
    retailer: RetailerType,
    id: number | string
  ): Promise<AxiosResponse<{ attributes: Record<string, TemplateRule> }>> => {
    return this.get(`/vendors/${retailer.toLowerCase()}/categories/${id}/attributes`);
  };

  resetValue = (filters: ResetValueBody, retailer: string) => {
    return this.post(`/vendors/${retailer}/products/reset`, filters);
  };

  exportTargetCsv = (filters: ExportProductsCsvBody, retailer: string) => {
    return this.post(`/vendors/${retailer}/products/export`, filters);
  };

  exportAmazonProducts = (filters: ExportProductsCsvBody) => {
    return this.post('/vendors/poc/products/amazon-export', filters);
  };

  performWalmartOCR = (pids: Array<string>) => {
    return this.post('/content/ocr', { pids });
  };

  performTargetOCR = (retailer: RetailerType, pids: Array<string>) => {
    return this.post(`/vendors/${retailer.toLowerCase()}/products/ocr`, {
      ...(WalmartRetailers.includes(retailer) ? { pids } : { tcins: pids }),
    });
  };

  performWalmartBrandAnalysis = (pid: string) => {
    return this.post(`/content/${pid}/brand-analysis`);
  };

  performRetailerBrandAnalysis = (retailer: string, pid: string) => {
    return this.post(`/vendors/${retailer.toLowerCase()}/products/${pid}/brand-analysis`);
  };

  performWalmartImageAnalysis = (pid: string) => {
    return this.post(`/content/${pid}/image-analysis`);
  };

  performRetailerImageAnalysis = (retailer: string, pid: string) => {
    return this.post(`/vendors/${retailer.toLowerCase()}/products/${pid}/image-analysis`);
  };

  suggestCategories = (data: SuggestCategoriesData): Promise<AxiosResponse<{ id: string }>> => {
    return this.post(`/vendors/${data.retailer.toLowerCase()}/products/categories-suggestions`, {
      imageUrls: data?.imageUrls,
    });
  };

  suggestCategoriesWithFiles = (data: SuggestCategoriesData): Promise<AxiosResponse<{ id: string }>> => {
    const formData = new FormData();
    data.photos.forEach(p => {
      formData.append('files', p);
    });
    return this.post(`/vendors/${data.retailer.toLowerCase()}/products/categories-suggestions`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  ensureProduct = (retailer: string, body: EnsureProductBody): Promise<AxiosResponse<{ id: string }>> => {
    return this.post(`/vendors/${retailer}/products/ensure-product`, omit(body, 'retailer'));
  };

  downloadCSV = (retailer: string, tcin: string): Promise<AxiosResponse<string>> => {
    return this.post(`/vendors/${retailer.toLowerCase()}/products/${tcin}/download-csv`);
  };

  saveScrapedAttributesData = (retailer: RetailerType, data: SaveScrapedAttributesData) => {
    return this.post(`/vendors/${retailer.toLowerCase()}/products/${data.tcin}/external-content`, data);
  };

  updateProductCategory = (retailer: RetailerType, tcin: string, categoryId: string) => {
    return this.post(`/vendors/${retailer.toLowerCase()}/products/${tcin}/category`, { category_id: categoryId });
  };

  addImagesXlsx = (retailer: string, files: Array<File>) => {
    const formData = new FormData();
    files.forEach(p => {
      formData.append('file', p);
    });

    return this.post(`/vendors/${retailer.toLowerCase()}/products/images-xlsx`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  deleteWalmartProductById = (scope: ScopeId, id: string) => {
    return this.delete(`/scopes/${scope}/products/${id}`);
  };

  deleteRetailerProductById = (retailer: RetailerType, scope: ScopeId, id: string) => {
    return this.delete(`/vendors/${retailer.toLowerCase()}/scopes/${scope}/products/${id}`);
  };
}

export const productsApi = new ProductsApiService(process.env.REACT_APP_AI_API);
