import { Modal } from 'common/ui/containers/modal';
import { ReactElement } from 'react';
import { Box, IconButton, Tab, Tabs } from '@mui/material';
import { Styles } from 'common/types/styles';
import { LightTooltip } from 'common/ui/containers/light-tooltip';
import AddIcon from '@mui/icons-material/Add';
import { GenerateInstruction } from 'modules/common/ai/components/pages/ai-conditional-instructions/generate-instruction';
import { GenerateSpecialInstructionResponse } from 'common/services/api/attributes/attributes-api.types';
import { InstructionsTable } from './instructions-table';
import { InstructionContext, instructionCtx } from './instruction-context';
import { InstructionModal } from './instruction-modal';
import { InstructionForm } from './instruction-form';
import { TabEntry, TableContext, tableCtx } from './table-context';
import { initialInstructionEntry } from './constants';
import { containerStyles } from './styles';

const styles: Styles = {
  head: { display: 'flex', justifyContent: 'center', position: 'relative' },
  addBtn: { position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' },
};

interface Props {
  open: boolean;
  onClose: () => void;
  pid: string;
  categoryID: number | string;
}

export function ConditionalInstructionsModal({ open, onClose, pid, categoryID }: Props): ReactElement {
  return (
    <Modal open={open} onClose={onClose} maxWidth="xl" title="Conditional Instructions">
      <Box sx={containerStyles}>
        <InstructionContext pid={pid} categoryID={categoryID}>
          <TableContext pid={pid}>
            <Box sx={styles.head}>
              <tableCtx.Consumer>
                {({ tab, setTab }) => (
                  <Tabs
                    onChange={(_, tab) => {
                      setTab(tab);
                    }}
                    value={tab}
                    centered
                  >
                    <Tab value={TabEntry.PID} tabIndex={TabEntry.PID} label="Applicable" />
                    <Tab value={TabEntry.ALL} tabIndex={TabEntry.ALL} label="ALL" />
                  </Tabs>
                )}
              </tableCtx.Consumer>
              <LightTooltip title="Add Instruction">
                <instructionCtx.Consumer>
                  {({ setSelectedInstruction, setInstructionModalOpen }) => (
                    <GenerateInstruction
                      onChangeInstruction={(value: GenerateSpecialInstructionResponse) => {
                        setSelectedInstruction({
                          id: 'new',
                          name: value?.conditionalInstructionParts?.name ?? '',
                          instruction: value?.conditionalInstructionParts?.instruction ?? '',
                          conditions: value?.conditionalInstructionParts?.conditions ?? '',
                          created_at: null,
                          updated_at: null,
                        });

                        setInstructionModalOpen(true);
                      }}
                      button={
                        <IconButton
                          sx={styles.addBtn}
                          onClick={() => {
                            setSelectedInstruction({ ...initialInstructionEntry });
                            setInstructionModalOpen(true);
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      }
                    />
                  )}
                </instructionCtx.Consumer>
              </LightTooltip>
            </Box>
            <InstructionsTable />
          </TableContext>
          <InstructionForm>
            <InstructionModal />
          </InstructionForm>
        </InstructionContext>
      </Box>
    </Modal>
  );
}
