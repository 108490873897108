export enum DetailsTabValue {
  Original = 0,
  Final,
  Compare,
}

export enum OptimizationStatus {
  Regular = 'Regular',
  SEO = 'SEO',
}

export enum SEOCompareSource {
  Original = 0,
  SEO,
}
