import { Box } from '@mui/material';
import { Styles } from 'common/types/styles';
import { ReactElement } from 'react';

const boolenOpSpacing = 10;

const getStyles = (renderPseudo: boolean): Styles => ({
  booleanOperator: {
    position: 'relative',
    zIndex: 2,
    color: '#7F8299',
    border: '1px solid #ebebeb',
    width: 46,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontSize: 10,
    borderRadius: '20px',
    mt: `${boolenOpSpacing}px`,
    mb: `${boolenOpSpacing}px`,
    ...(renderPseudo
      ? {
          ':before': {
            content: '""',
            zIndex: 1,
            width: '1px',
            backgroundColor: '#ebebeb',
            position: 'absolute',
            height: `${boolenOpSpacing + 1}px`,
            top: `${-boolenOpSpacing - 1}px`,
            left: '50%',
          },
          ':after': {
            content: '""',
            zIndex: 1,
            width: '1px',
            backgroundColor: '#ebebeb',
            position: 'absolute',
            height: `${boolenOpSpacing + 1}px`,
            bottom: `${-boolenOpSpacing - 1}px`,
            left: '50%',
          },
        }
      : {}),
  },
});

interface Props {
  withConnections?: boolean;
  value: 'or' | 'and';
}

export function BooleanOperator({ withConnections = false, value }: Props): ReactElement {
  const styles = getStyles(withConnections);

  return (
    <Box component="span" sx={styles.booleanOperator}>
      {value}
    </Box>
  );
}
