import { Box, Button, Popover, TextField } from '@mui/material';
import { useGenerateInstructionMutation } from 'common/hooks/api/mutations/use-attributes-mutation';
import { GenerateSpecialInstructionResponse } from 'common/services/api/attributes/attributes-api.types';
import { Styles } from 'common/types/styles';
import { ButtonWithLoading } from 'common/ui/containers/button-with-loading';
import { FieldLabelWrapper } from 'common/ui/inputs/field-label-wrapper';
import { ChangeEvent, cloneElement, ReactElement, ReactNode, useState } from 'react';
import toast from 'react-hot-toast';

const styles: Styles = {
  container: {
    maxWidth: 450,
    minWidth: 400,
    width: 'fit-content',
    p: '20px',
    boxSizing: 'border-box',
    fontSize: 14,
    wordBreak: 'break-word',
    lineHeight: '19px',
  },
  btnWrapper: { display: 'flex', width: '100%', justifyContent: 'flex-end', mt: 2 },
};

interface Props {
  button?: ReactNode;
  buttonText?: string;
  generateBttnText?: string;
  onChangeInstruction: (value: GenerateSpecialInstructionResponse) => void;
}

export function GenerateInstruction({
  onChangeInstruction,
  button,
  buttonText,
  generateBttnText,
}: Props): ReactElement {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [value, setValue] = useState<string>('');

  const { mutate: mutateGenerate, isLoading } = useGenerateInstructionMutation();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleInstructionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleGenerateInstruction = () => {
    mutateGenerate(
      { userInput: value },
      {
        onSuccess: ({ data }) => {
          setValue('');
          onChangeInstruction(data);
          toast.success('Special Instruction has been generated!');

          handlePopoverClose();
        },
      }
    );
  };

  const renderedButton = button ? (
    cloneElement(button as ReactElement, { onClick: handlePopoverOpen })
  ) : (
    <Button onClick={handlePopoverOpen}>{buttonText || 'Generate Special Instruction'}</Button>
  );

  return (
    <>
      {renderedButton}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableRestoreFocus
        disableScrollLock
      >
        <Box sx={styles.container}>
          <FieldLabelWrapper label="Generate Special Instruction">
            <TextField fullWidth value={value} onChange={handleInstructionChange} minRows={3} maxRows={3} multiline />
          </FieldLabelWrapper>
          <Box sx={styles.btnWrapper}>
            <ButtonWithLoading
              loading={isLoading}
              disabled={!value?.length}
              color="primary"
              variant="contained"
              onClick={handleGenerateInstruction}
            >
              {generateBttnText || 'Generate'}
            </ButtonWithLoading>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
