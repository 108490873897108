import { useScopesListQuery } from 'common/hooks/api/queries/use-scopes-query';
import { userService } from 'common/services/user.service';

interface Props {
  ws?: number;
  retailer?: string;
}

export function useSelectScopes({ ws, retailer }: Props) {
  const { data: scopes } = useScopesListQuery({
    ...(ws ? { ws_scope: ws } : {}),
    ...(retailer ? { retailer } : {}),
    productsCount: true,
    categoriesCount: true,
    limit: 100,
    sort: 'created_at:desc',
    sub_vendor_id: userService.getSubRetailer(),
  });

  return scopes;
}
