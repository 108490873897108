import { Box, Popover, Slider, SliderProps, Typography } from '@mui/material';
import { Mark } from '@mui/material/Slider/useSlider.types';
import { SystemStyleObject } from '@mui/system';
import { theme } from 'common/constants/theme';
import { Styles } from 'common/types/styles';
import { MouseEvent, ReactElement, useMemo, useState } from 'react';

const styles: Styles = {
  mark: { cursor: 'pointer' },
  popover: { p: theme.spacing(1, 6) },
};

interface ExtendedSliderProps extends SliderProps {
  marks: Mark[];
  markSX?: SystemStyleObject;
}

export function ExpandableSlider({ markSX, ...props }: ExtendedSliderProps): ReactElement {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement>(null);

  const handlePopoverOpen = (e: MouseEvent<HTMLElement>) => {
    setPopoverAnchor(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  const currentMark = useMemo(() => {
    // eslint-disable-next-line react/destructuring-assignment
    return props.marks.find(m => m.value === props.value);
  }, [props.marks, props.value]);

  return (
    <>
      <Typography sx={[styles.mark, markSX]} onClick={handlePopoverOpen}>
        {currentMark?.label ?? 'Select value'}
      </Typography>
      <Popover open={Boolean(popoverAnchor)} anchorEl={popoverAnchor} onClose={handlePopoverClose}>
        <Box sx={styles.popover}>
          <Slider {...props} />
        </Box>
      </Popover>
    </>
  );
}
