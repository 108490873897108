import { useMutation } from '@tanstack/react-query';
import { exportDataApi } from 'common/services/api/export-data/export-data-api.service';

export function useExportStatusMutation() {
  const mutation = useMutation({
    mutationFn: async (id: string) => {
      const { data } = await exportDataApi.getExportStatus(id);
      return data;
    },
  });

  return mutation;
}
