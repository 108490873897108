import { useQuery } from '@tanstack/react-query';
import { actionNotificationsApi } from 'common/services/api/action-notifications/action-notifications.service';
import { GetNotificationsParams } from 'common/services/api/action-notifications/action-notifications.types';

export enum ActionNotificationsQueryKey {
  NotificationsList = 'NotificationsList',
}

export function useActionNotificationsListQuery(params: GetNotificationsParams, refetchInterval?: number) {
  const query = useQuery({
    queryKey: [ActionNotificationsQueryKey.NotificationsList, params],
    refetchInterval,
    queryFn: async () => {
      const { data } = await actionNotificationsApi.getNotifications(params);
      return data;
    },
  });

  return query;
}
