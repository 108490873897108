import { Box, Button, Typography } from '@mui/material';
import { Styles } from 'common/types/styles';
import { ReactElement } from 'react';
import { Modal } from '../../containers/modal';

export interface ApproveVocabulary {
  yesBtn: string;
  noBtn?: string;
  description: string;
}

const styles: Styles = {
  button: { minWidth: 100, marginLeft: 1, ':first-child': { marginLeft: 0 } },
  buttons: { marginTop: 2, display: 'flex', justifyContent: 'flex-end' },
};

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCancel?: () => void;
  hideCancel?: boolean;
  vocabulary: ApproveVocabulary;
}

export function ApproveModal({
  open,
  onClose,
  onConfirm,
  vocabulary,
  onCancel,
  hideCancel = false,
}: Props): ReactElement {
  onCancel = onCancel || onClose;

  return (
    <Modal open={open} onClose={onClose} maxWidth="xs" title="Confirm action">
      <Typography>{vocabulary.description}</Typography>
      <Box sx={styles.buttons}>
        <Button color="primary" sx={styles.button} variant="contained" onClick={onConfirm}>
          {vocabulary.yesBtn}
        </Button>
        {!hideCancel && (
          <Button color="inherit" variant="outlined" sx={styles.button} onClick={onCancel}>
            {vocabulary.noBtn}
          </Button>
        )}
      </Box>
    </Modal>
  );
}
