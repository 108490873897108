import { useState } from 'react';

interface Params {
  defaultLimit?: number;
  defaultPage?: number;
}

export function usePagination(params?: Params) {
  const [limit, setLimit] = useState<number>(params?.defaultLimit ?? 10);
  const [page, setPage] = useState<number>(params?.defaultPage ?? 0);

  return { limit, page, setLimit, setPage };
}
